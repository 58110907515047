@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.6); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  backdrop-filter: blur(5px);
}
.modal .form-select,
.modal .form-control {
  font-size: 14px !important;
  padding: 7px 5px !important;
  border-radius: 0 !important;
}
.modal .w-33 input::placeholder {
  font-size: 11px !important;
}
.model_inner .modal-main {
  width: 30% !important;
  min-width: 300px;
}
.model_inner .modal-main p {
  font-size: 14px !important;
}
.modal .w-33 {
  width: 33.33% !important;
}
.modal .w-33 p {
  font-size: 12px !important;
}
.modal-main {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 1024px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-x: auto;
  max-height: 90vh;
  scrollbar-width: none;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.display-block .modal-main {
  display: block;
  /* display: flex; */
  animation: modalFadeIn 0.3s ease-in-out forwards;
}

.display-none .modal-main {
  /* display: none; */
  display: block;
  opacity: 0;
  transform: scale(0.8);
  animation: modalFadeOut 0.3s ease-in-out forwards;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.cursor-pointer-add {
  cursor: pointer;
}
.sub-header-sticy {
  position: fixed;
  z-index: 9;
  background-color: #f9f9f9;
  top: 0px;
  color: #f6f6f6b5;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}
.sub-header-sticy-no {
  transition: 0.5s;
}

body {
  max-width: 1980px !important;
  margin: 0 auto !important;
}
.widget-1 .widget-round .bg-round,
.container-fluid.mt-7.px-5.pb-5 {
  z-index: 0 !important;
  position: relative !important;
}

.mini-header .sidebar.active {
  z-index: 999999999 !important;
}
.main_header .logo-img-header {
  width: 200px;
}
.common-status-button-containner .modal-main {
  width: 40%;
  min-width: 300px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.common-status-button-containner .modal-main .sdvds h5 {
  margin: 20px 0 10px 0 !important;
  background-color: transparent;
  color: #000 !important;
}
.common-status-button-containner .commonstatusbutton-img {
  width: 80px;
}
.common-status-button-containner .modal-footer {
  border: none;
  text-align: center;
  display: block;
}
.common-status-button-containner .modal-footer .modal-footer-cancel-btn {
  border: 1px solid black;
  background-color: transparent;
  padding: 10px;
  font-size: 16px;
  width: 100px;
  border-radius: 3px;
}
.common-status-button-containner .modal-footer .modal-footer-confirm-btn {
  border: 1px solid black;
  background-color: #004b44;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  width: 100px;
  border-radius: 3px;
}
@media only screen and (max-width: 1024px) {
  .search-box.active {
    width: 250px;
  }
  .main_header .logo-img-header {
    width: 130px;
  }
  .main_head {
    display: none;
  }
  .mini-header {
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .main_head {
    display: block;
  }
  .mini-header {
    display: none;
  }
}
@media only screen and (max-width: 468px) {
  .main_head .main_header .list-unstyled {
    position: unset;
    gap: 10px;
  }
}
