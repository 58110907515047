.success-btn,
.model_inner .sdvds button {
  background-color: #004d42;
  width: 150px;
  border: 2px solid #004d42;
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.success-btn:hover {
  background-color: #000;
}

.cancel-btn {
  border: 2px solid #004d42;
  width: 150px;
  background: none;
  color: #004d42;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-btn:hover {
  background-color: #004d42;
  color: #fff;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
p {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.7px;
}

.btn {
  font-size: 14px;
}

*.btn:focus {
  box-shadow: none !important;
}

button:focus {
  outline: none !important;
}
.filter_button {
  border: 1px solid #cccccc;
  /* color: #004d42; */
  height: 40px;
  font-size: 14px !important;
  font-family: "memv" !important;
  border-radius: none !important;
}
.filter_button:hover {
  background-color: #004d42;
  color: #fff;
}
.message {
  padding: 0 25px;
  text-align: left;
  min-width: 100%;
  height: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease;
  font-family: "memv" !important;
  font-weight: normal;
}

.message.active {
  opacity: 1;
}
.listing_button {
  padding: 0 !important;
  border: 1px solid #cccccc !important
  ;
}
#searchtrans {
  padding: 0 0 0 5px !important;
}
#propunitno,
#searchtrans {
  padding: 0 0 0 5px !important;
}
.datatable-header-main {
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 20px 0;
}
.search_box {
  border: 1px solid #cccccc;
  padding: 5px 5px 5px 28px;
  font-size: 16px !important;
  outline: none;
  height: 40px;
  width: 250px;
}
.add_button {
  height: 40px;
  font-size: 16px !important;
  width: 200px;
}
.container {
  width: 100% !important;
  max-width: 1500px !important;
}

.container .ddvZdT {
  white-space: wrap !important;
}
.container .ejfNUe {
  min-height: 300px;
}
.css-13cymwt-control,
.css-t3ipsp-control {
  border-radius: 0px !important;
}
.css-1nmdiq5-menu {
  z-index: 9 !important;
}
.tiny_add_text_button_container {
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-around;
  background-color: whitesmoke;
  padding: 10px;
}
.tiny_add_text_button_container .tiny_add_text_button {
  padding: 5px 10px;
  color: #fff;
  background-color: #004d42;
  border: 2px solid #004d42;
  outline: none;
  font-size: 14px;
  transition: 0.3s;
}
.tiny_add_text_button_container .tiny_add_text_button:hover {
  transition: 0.3s;
  color: #000;
  background-color: #fff;
  border: 2px solid #004d42;
}
@media only screen and (max-width: 768px) {
  #mouse-follower,
  #dot {
    display: none !important;
  }
}
@media only screen and (max-width: 600px) {
  .tiny_add_text_button_container {
    display: block;
  }
  .tiny_add_text_button_container .tiny_add_text_button {
    width: 100%;
    margin: 10px 0;
    display: block;
  }
}
