.add-tenants .row div p,
.add-tenants .row div .form-control,
.add-tenants .row div input::placeholder,
.add-tenants .row div .document-type {
  font-size: 12px;
}
.add-tenants .custom-select,
.add-tenants .all-lead-add-select-box-style,
.add-tenants .all-lead-add-select-box-style li {
  width: 100% !important;
}
.feature-page .select-options,
.feature-page.select-options:hover,
.feature-page .select-option-value,
.feature-page .select-option-value:hover {
  width: 100% !important;
}

/* Header Style Start... */

.sub-dropdown {
  position: relative;
}

.sub-dropdown-content {
  display: none;
  position: absolute;
  left: -87%;
  top: -100%;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.sub-dropdown:hover .sub-dropdown-content {
  display: block;
}
.sub-dropdown:hover .create_sales_offer_main {
  background-color: #004d42;
  color: #fff;
}
.current-img-show {
  width: 200px;
  height: 100px;
  border-radius: 5%;
}
/* Header Style End... */
.filter_button:hover .link-hover {
  color: #fff !important;
}
.add-unit-model-containner .react-date-picker,
.add-agent-main-containner .react-date-picker {
  width: 100% !important;
}
.add-unit-model-containner .all-lead-add-select-box-style,
.add-unit-model-containner .select-option-value,
.add-unit-model-containner .select-header {
  width: 100% !important;
  font-size: 12px;
}
.public-folder-containner .form-control,
.add-agent-main-containner .form-control,
.add-agent-main-containner .form-control::placeholder {
  font-size: 12px !important;
}
