.lead-detail-container {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  margin: 50px 10px;
  padding: 20px 20px 30px 20px !important;
  border: 1px solid #000;
}
.lead-detail-container .lead-detail-container-child {
  width: 33%;
  margin: 0px 0px 0px 30px;
  text-align: center;
}
.remove-ml-lead-details {
  margin-left: 0px !important;
}
.lead-detail-container .lead-detail-container-child .lead-data-key-value {
  text-align: center !important;
  color: black;
  font-size: 14px;
}
.lead-detail-container .lead-detail-container-child .lead-data-key-value-data {
  text-align: center !important;
  color: black;
  padding: 0 5px;
  font-size: 14px;
}
.lead-detail-container .lead-detail-container-child-data {
  /* border-bottom: 1px solid #cccccc; */
  border-bottom: 1px solid #efefef;
  line-height: 20px;
}

.note-containner-lead-list {
  width: 33%;
  border: none !important;
}

.lead-detail-history-btn {
  justify-content: space-between;
}

.lead-detail-history-btn button {
  border: 1px solid gray;
  border-radius: 4px;
  float: inline-end;
  display: block;
  padding: 5px 10px;
  background-color: transparent;
  transition: 0.5s;
  cursor: pointer;
  margin: 8px 0 0px 0;
  width: 100px;
  height: 40px;
  color: #000;
  /* border: none; */
  font-size: 16px;
  font-weight: 600;
}
.lead-detail-history-btn button:hover {
  background: linear-gradient(132deg, #004e41, #004e41, #004e41);
  color: #fff;
  /* color: #004e41; */
  /* color: #4d32c0; */
  transition: 0.5s;
}
.lead-detail-history-btn h6 {
  margin: 20px 0 10px 0;
  font-size: 16px;
  text-align: start !important;
}
.note-text-area-containner {
  position: relative;
}
.note-text-area-containner textarea {
  width: 100%;
  padding: 5px 10px;
  height: 150px;
  color: #000;
  z-index: 9;
  border-radius: 5px;
  border: 3px solid #004d42;
}
.lead-note-details-btn-containner {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.lead-list-cancel-btn {
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
}
.lead-list-cancel-btn img {
  width: 25px;
}
.lead-list-submit-btn img {
  width: 30px;
}

.lead-list-submit-btn {
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: 0.5s;
  background: transparent;
}
.lead-list-submit-btn:hover {
  color: black;
  transition: 0.5s;
}
.note-chat-containner {
  border: 1px solid black;
  margin-top: 10px;
}
.note-chat-containner ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: auto;
  height: 180px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.note-chat-containner ul li {
  text-align: right;
  padding: 10px;
  margin: 0px !important;
}
.note-chat-containner ul li .entete {
  margin-bottom: 5px;
}
.note-chat-containner ul li h3 {
  color: #bbb;
  font-size: 12px;
}
.note-chat-containner ul li h2 {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
}
.note-chat-containner ul li .triangle {
  border-color: transparent transparent #6fbced transparent;
  /* margin-left: 375px; */
  margin-left: 960px;
}
.note-chat-containner ul li .messages {
  padding: 5px 20px;
  color: #fff;
  line-height: 25px;
  max-width: 90%;
  display: inline-block;
  text-align: left;
  border-radius: 5px;
  background-color: #004e41;
}
.cansel-submit-note-btn {
  margin-top: 10px;
  text-align: left;
}
.cansel-btn-note-chat {
  border: 1px solid black;
  padding: 5px 10px;
  background-color: transparent;
  margin: 0 10px 0 0;
}
.submit-btn-note-chat {
  border: 1px solid black;
  padding: 5px 10px;
  background-color: #004e41;
  margin: 0 10px 0 0;
  color: #fff;
  transition: 0.5s;
}
.submit-btn-note-chat:hover {
  background-color: #fff;
  color: #000;
  transition: 0.5s;
}

/* list page style start */
.unit-number-list {
  height: 36px !important;
  outline: none;
  border: 1px solid #cccccc !important;
}
.unit-number-list::placeholder {
  font-size: 14px !important;
}
/* list page style End */

/* Property finder leads style start */
.property-finder-leads-containner .searchbox-parant-main {
  float: right;
}
/* Property finder leads style end */

/* bayut leads style start */
.bayut-lead-list-containner .custom-select {
  width: 200px !important;
  margin: 0 10px 0 0;
}
.link-bayut-leads-data-table {
  color: blue;
  cursor: pointer;
  font-size: 11px;
  padding: 0 2px;
}
.link-bayut-leads-data-table:hover {
  color: #4d32c0;
  text-decoration: underline;
}
/* bayut leads style end */

/* Old Lead Pade style start */
.show-old-lead-action-icon-main {
  gap: 5px;
  display: flex;
}
.show-old-lead-action-icon-main .show-old-lead-action-icon {
  padding: 7px;
  /* background-color: #004e41; */
  color: white;
  font-size: 14px;
  cursor: pointer;
}
.line-height-add {
  line-height: 18px;
}
.add-width-old-details-page-list {
  width: 25% !important;
  padding: 0px 10px;
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.add-shadow-old-details {
  box-shadow: 0px 3px 20px #f2f2f2 !important;
}

.add-style-details-box {
  background: #00463c21;
  border-radius: 8px;
  padding: 10px;
  /* margin: 0 0 20px 0; */
  /* display: flex; */
  align-items: center;
  min-height: 70px;
  width: 24% !important;
  flex-wrap: wrap;
}
.notes-lead-details-page {
  width: 74% !important;
}
.notes-lead-details-pagess {
  width: 49% !important;
}
.card-containners {
  gap: 1.3%;
}
/* .add-all-deals .select-header,
.edit-old-lead-detail .select-header {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 8px;
} */

.add-all-deals .form-control,
.edit-old-lead-detail .form-control {
  font-size: 12px;
  padding: 10px !important;
}
.add-all-deals .form-control::placeholder,
.edit-old-lead-detail .form-control::placeholder {
  font-size: 12px !important;
}

.add-all-deals .row div p,
.edit-old-lead-detail .row div p {
  font-size: 12px;
}
.add-all-deals .all-lead-add-select-box-style,
.edit-old-lead-detail .all-lead-add-select-box-style {
  width: 100% !important;
}
.edit-old-lead-detail .all-lead-add-select-box-style li {
  width: 100%;
}
.add-all-deals .select-options .select-option:hover {
  width: auto;
}
.radio-button-add-all-deals {
  width: 15px;
  height: 15px;
}
.radio-button-add-all-deals .radio-button-add-all-deals-text {
  font-size: 12px;
}
.add-all-deals .react-date-picker {
  width: 100% !important;
}
.add-all-deals .add-all-deals-checkbox {
  width: 10%;
  margin: 5px 0 0 0;
}
.select-old-deals-btn {
  float: inline-end;
  padding: 10px 5px;
  border: 1px solid black;
  border-radius: 3px;
  background-color: #fff;
  transition: 0.5s;
}
.select-old-deals-btn:hover {
  background-color: #004d42;
  color: #fff;
  transition: 0.5s;
}
.add-all-deals .model_inner .modal-main {
  height: 60%;
}
.add-all-deals .close-button-model {
  position: absolute;
  bottom: 20px;
  right: 15px;
}
.all-list-main-containner .react-date-picker,
.all-list-main-containner .unit-number-list {
  width: 100% !important;
}
.olad-lead-card-main-containner {
  box-shadow: 0px 3px 20px #f2f2f2 !important;
  border-radius: 10px;
}
.olad-lead-card-main-containner .olad-lead-card {
  margin: 10px;
}
.olad-lead-card-main-containner .old-lead-details-data {
  background: #00463c21;
  border-radius: 8px;
  padding: 10px;
  /* margin: 0 0 20px 0; */
  /* display: flex; */
  align-items: center;
  transition: all 0.3s ease;
}
.olad-lead-card-main-containner .old-lead-details-data:hover {
  transform: translateY(-5px);
  transition: all 0.3s ease;
}
.page-containners-main {
  padding-bottom: 65px !important;
}
.container .row p {
  padding: 5px 0px;
}
.form-control {
  padding: 5px 10px !important;
}
.add-agent-form-containner {
  display: flex;
}
.chat_containner_box {
  padding: 10px;
  margin: 10px 0px;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  /* background-color: #f3f3f3; */
  background-color: #fff;
}
.chat_containner_box .chat_containner_box_main {
  width: 100%;
  margin: 10px 0;
}
.chat_containner_box .chat_containner_box_main_right {
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.chat_containner_box .chat_containner_box_chield {
  width: 100%;
  position: relative;
  justify-content: center;
}
.chat_containner_box .align-right .chat_containner_box_chield {
  justify-content: end;
}
.chat_containner_box .align-left .chat_containner_box_chield {
  justify-content: start;
}
.chat_containner_box .chat_image_containner {
  padding: 10px;
  display: flex;
  align-items: end;
}
.chat_containner_box .align-left .chat_image_containner {
  align-items: start;
}
.chat_containner_box .chat_image_containner img {
  width: 40px;
  height: 40px;
}
.chat_containner_box .chat_message_box_containner {
  border: 1px solid #d9d9d9;
  padding: 5px 15px;
  /* width: 100%; */
  text-align: start;
  border-radius: 10px;
  border-top-left-radius: 0px;
}
.chat_containner_box .chat_message_box_containner span {
  display: block;
}
.chat_containner_box .chat_message_box_containner .message_messages {
  font-size: 14px;
}
.chat_containner_box .chat_message_box_containner .message_type {
  font-size: 14px;
  word-wrap: break-word;
  min-width: 100px;
}
.chat_containner_box .chat_message_box_containner .message_time {
  font-size: 12px;
  color: gray;
  text-align: end;
}

.chat_containner_box .align-left .chat_message_box_containner:before {
  content: "";
  top: 20px;
  left: 52px;
  position: absolute;
  background: transparent;
  height: 0px;
  width: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #005448;
}
.chat_containner_box
  .chat_containner_box_main_right
  .chat_message_box_containner {
  border-radius: 10px;
  border-top-right-radius: 0px !important;
}
.chat_containner_box .align-right .chat_message_box_containner:before {
  content: "";
  bottom: 20px;
  right: 51px !important;
  position: absolute;
  background: transparent;
  height: 0px;
  width: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #005448;
}

.chat_containner_box .align-left {
  justify-content: flex-start;
}

.chat_containner_box .align-right {
  justify-content: flex-end;
}

.chat_containner_box .chat_image_containner img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat_containner_box .chat_message_box_containner {
  max-width: 70%;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  background-color: #005448;
  color: #fff;
}

/* Old Lead Pade style end */

@media only screen and (max-width: 400px) {
  .success-btn,
  .cancel-btn {
    width: 100px !important;
  }
}
@media only screen and (max-width: 575px) {
  .datatable-header-main {
    display: block !important;
  }
  .datatable-header-main .filter_button {
    margin: 5px 0px;
    width: 100%;
    /* height: auto !important; */
  }
  .datatable-header-main .search_box {
    margin: 5px 0px;
    width: 100%;
  }
  .searchbox-parant-main i {
    top: 19px;
  }
  .data-table-single-search-input-box-container {
    margin: 20px 12px !important;
  }
  .data-table-single-search-input-box-container input {
    width: 100% !important;
  }
  .data-table-single-search-input-box-container i {
    left: 10px;
  }
}
@media only screen and (max-width: 700px) {
  .lead-detail-container {
    display: block !important;
  }
  .lead-detail-container .lead-detail-container-child {
    width: 100%;
    margin: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .dashbord-date-filter-btns {
    display: block !important;
  }
  .dashbord-date-filter-btns div,
  .dashbord-date-filter-btns div button {
    width: 100%;
  }
  .dashbord-date-filter-btns div button {
    margin: 5px 0;
  }
}
@media only screen and (max-width: 1024px) {
  .page-containners-main {
    margin-top: 50px !important;
    padding-bottom: 50px !important;
  }
}
