.mini-header {
  margin: 0px;
  padding: 0px;
}
.mini-header .text-primary {
  color: #5a8dee !important;
}
.mini-header .btn-primary {
  border-color: #f9f9f9 !important;
  background-color: #f9f9f9 !important;
  color: #004a40;
  padding: 5px 10px !important;
}
.mini-header .navbar {
  border-radius: 5px;
}
.mini-header .sidebar {
  width: 100%;
  /* min-height: 100vh; */
  height: 100%;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #fff;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 1;
  transition: 0.5s;
}
.mini-header .sidebar.active {
  left: 0;
}
.mini-header .sd-header {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  padding: 15px;
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
  color: #fff;
}
.mini-header .sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.mini-header .sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}
.mini-header .sd-body {
  overflow-x: hidden;
  background-color: #fff;
  /* height: 100vh; */
  height: 100%;
  color: #000;
  /* padding-bottom: 200px !important; */
}
.mini-header .sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}
.mini-header .sd-body ul li {
  list-style: none;
  margin-bottom: 8px;
}
.mini-header .sd-body ul li .sd-link {
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  border-radius: 0px;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid #d9d9d9;
}
.mini-header .container-fluid {
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
}
.mini-header .navbar-brand .mini-logo-img,
.sd-header .mini-logo-img {
  width: 200px;
  height: 40px;
}
.mini-header .mini-header-sub-containner {
  position: fixed;
  z-index: 99;
}

.mini-header .sidebar .dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mini-header .sidebar .sub-dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
  animation: slideDown 300ms ease-in-out;
}

.mini-header .sidebar .dropdown-item {
  padding: 0px;
  text-decoration: none;
  color: #000;
}

.mini-header .sidebar .dropdown-item:hover {
  background-color: #f0f0f0;
}

.mini-header .sidebar .dropdown-menu .dropdown-item {
  position: relative;
}

.mini-header .sidebar .dropdown-menu .dropdown-item:hover .sub-dropdown-menu {
  display: block;
}

.mini-header .sidebar .fa-chevron-up {
  margin-left: 5px;
}

.mini-header .sidebar .fa-chevron-down {
  margin-left: 5px;
}
.mini-header .sidebar .worl-parant-menu {
  position: relative;
}
.mini-header .sidebar .worl-parant-menu-chield {
  position: unset;
  top: 45px;
  left: 0px;
  box-shadow: none !important;
  animation: slideDown 300ms ease-in-out;
}
.mini-header .options-works {
  background-color: white !important;
  color: #000 !important;
}
.mini-header .sub-dropdown-menu li {
  margin-bottom: 0px !important;
}
.mini-header .dropdown-item .sd-link {
  border-bottom: 1px solid black;
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 0px !important;
  transition: 0.5s;
  padding-left: 40px !important;
}
.mini-header .dropdown-item .sd-link:hover {
  transition: 0.5s;
  background-color: #d0c7c7b5 !important;
}
.mini-header .add-sub-menu-hover li .options-works:hover {
  background-color: #f0ececb5 !important;
  color: #000 !important;
  transition: 0.5s;
}
.mini-header .add-sub-menu-hover li .options-works {
  background-color: #fff !important;
  color: #000 !important;
  transition: 0.5s;
}
.mini-header-profile-menu ul,
.mini-header-profile-menu ul li,
.mini-header-profile-menu ul a {
  font-size: 12px !important;
}
.menu.active ul li span {
  cursor: pointer;
}
@media only screen and (max-width: 468px) {
  .mini-header .navbar-brand .mini-logo-img,
  .sd-header .mini-logo-img {
    width: 150px;
    height: 30px;
  }
  .mini-header .mini-header .btn-primary {
    padding: 10px;
  }
  .mini-header .search-box.active {
    width: 180px;
  }
}
@media only screen and (max-width: 468px) {
  .mini-header .search-box.active {
    width: 150px;
  }
}
@media only screen and (max-width: 1024px) {
  .action .menu.active {
    right: -15px !important;
    top: 45px !important;
  }
  body .main_head {
    display: none !important;
  }
  .search-box .search-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .search-box {
    width: 30px;
    height: 30px;
  }
  .search-box .search-icon.active {
    height: 29px;
    line-height: 30px;
    width: 30px;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* @keyframes slideDownSunHeader {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.sub-header-sticy {
  animation: slideDownSunHeader 0.55s ease-out;
} */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #004d42;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #01382f;
}
#sidebar-main .navbar-dropdown .sub-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #004d42;
}

.dropdown {
  display: none;
  position: absolute;
  transition-duration: 1s;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-top: 7px;
  min-width: 170px;
}

.navbar-dropdown:hover > .dropdown {
  display: block !important;
  transition-duration: 1.1s;
}

.dropdown a {
  display: block;
  padding: 15px 30px;
  color: #000;
}

.dropdown a:hover {
  color: #fff;
  background-color: #004d42;
}

.dropdown .dropdown {
  display: none;
  position: absolute;
  left: 100%;
  top: -8px;
  transition-duration: 1s;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  max-height: 200px;
  overflow-y: auto;
}

.dropdown:hover > .dropdown {
  display: block !important;
  transition-duration: 1.1s;
}

.notification-counter {
  position: absolute;
  top: -1px;
  background: red;
  width: 15px;
  border-radius: 100px;
  height: 15px;
  text-align: center;
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  left: 12px;
}

.main_head {
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  background-color: #ffffff;
  z-index: 1000;
  width: 100%;
}
.main_head span,
.main_head .sub-dropdown a,
.main_head .dropdown a {
  font-family: "memv" !important;
  font-weight: 600 !important;
  margin-top: auto;
}

.main_head .main_header .list-unstyled {
  gap: 10px;
  width: 50%;
}

.main_header {
  width: 100%;
  align-items: center;
  padding: 22px 15px;
  position: relative;
  width: 100%;
  box-shadow: none;
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
}

.sidebar-links {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
  padding: 0 5px;
  transition: all 0.5s ease;
  white-space: nowrap;
  overflow-x: auto !important;
}

.sidebar-links > li {
  position: unset !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
body .sidebar-links > li {
  position: unset !important;
}

.sidebar-links > li > a {
  display: block;
  color: #2f2f3b;
  /* line-height: 23px; */
  text-decoration: none;
  text-transform: capitalize;
  padding: 5px 15px;
  margin: 0 3px;
  letter-spacing: 0.04em;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.sidebar-links li:last-child .sidebar-link {
  padding-right: 0 !important;
}

.sidebar-links li:first-child .sidebar-link {
  padding-left: 0 !important;
}

.menu {
  position: absolute;
  top: calc(100% + 24px);
  right: 16px;
  width: 200px;
  min-height: 100px;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: 300ms;
  z-index: 1;
}

.menu::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 14px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
  z-index: -1;
}

.menu.active {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.profile {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  text-align: end;
}

.profile h3 {
  text-align: end;
  line-height: 1;
  margin-bottom: 4px;
  font-weight: 600;
}

.profile p {
  line-height: 1;
  font-size: 14px;
  opacity: 0.6;
}

.profile .img-box {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.profile .img-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu {
  position: absolute;
  top: calc(100% + 24px);
  right: 16px;
  width: 200px;
  min-height: 100px;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: 300ms;
}

.menu::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 14px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
  z-index: -1;
}

.menu.active {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.menu ul {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background: #fff;
}

.menu ul li {
  list-style: none;
}

.menu ul li a {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  gap: 6px;
}

.menu ul li a i {
  font-size: 1.2em;
}

.action .menu {
  position: absolute;
  right: -10px;
  padding: 10px 20px;
  background-color: #fff;
  width: 200px;
  border-radius: 15px;
  transition: 0.5s;
}

.action .menu.active {
  top: 55px;
  visibility: visible;
  z-index: 99;
  right: -10px;
}

.action .menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 28px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
}

.action .menu h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding-top: 20px;
  font-weight: 500;
  color: #2a3132;
  line-height: 1.2em;
}

.action .menu h3 span {
  font-size: 14px;
  color: #b3b3b3;
  font-weight: normal !important;
}

.action .menu ul li {
  list-style: none;
  padding: 10px 0;
  border-top: 1px solid #fff;
  display: flex;
  column-gap: 15px;
  align-items: center;
  vertical-align: middle;
  justify-content: flex-start;
}

.action .menu ul li img {
  max-width: 20px;
  /* margin-right: 10px; */
  opacity: 1;
  transition: 0.5s;
}

.action .menu ul li:hover img {
  opacity: 1;
}

.action .menu ul li a {
  display: inline-block;
  text-decoration: none;
  /* color: #2a3132; */
  font-weight: bold;
  transition: 0.5s;
  font-family: "memv" !important;
}

.action .menu ul li:hover a {
  color: #004d42;
}

.search-box {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.search-box.active {
  width: 350px;
}

.search-box input {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 50px;
  background: #fff;
  outline: none;
  padding: 0 60px 0 20px;
  font-size: 18px;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.search-box input.active {
  opacity: 1;
}

.search-box input::placeholder {
  color: #a6a6a6;
}

.search-box .search-icon {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #004d42;
  cursor: pointer;
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.search-box .search-icon.active {
  right: 2px;
  height: 39px;
  line-height: 40px;
  width: 40px;
  font-size: 14px;
  background: #004d42;
  color: #fff;
  transform: translateY(-50%) rotate(360deg);
}

.search-box .cancel-icon {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.search-box .cancel-icon.active {
  right: -12px;
  transform: translateY(-50%) rotate(360deg);
}

.search-box .search-data {
  text-align: center;
  padding-top: 7px;
  color: #fff;
  font-size: 18px;
  word-wrap: break-word;
}

.search-box .search-data.active {
  display: none;
}

.custom_notifyimage {
  margin-top: -15px !important;
}

.message-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.announcement-bar {
  width: 100%;
  overflow: hidden;

  color: #fff;
  position: relative;
  height: 50px;
}

@media only screen and (max-width: 991px) {
  .dashbord-list-pipeline .col {
    flex: 100% !important;
    width: 100% !important;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1024px) {
  .dashbord-list-pipeline .col {
    flex: 33.33% !important;
    width: 33.33% !important;
    max-width: 33.33% !important;
  }
}
.main_head {
  display: inline !important;
}
nav.sidebar-main {
  position: sticky !important;
  top: -3px;
  background: #fff;
  z-index: 9;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}
span.notification-profile-text {
  font-weight: bold !important;
  color: #000 !important;
}
