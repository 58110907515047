.otp_model_main_containner .otp-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.otp_model_main_containner .otp-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 450px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  animation: slideInOtp 0.4s ease-out;
}

.otp_model_main_containner .close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 26px;
  cursor: pointer;
}

@keyframes slideInOtp {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* --------------------------------------------------- */

/* OtpForm.css */
.otp_model_main_containner .otp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -30px;
}

.otp_model_main_containner .otp-input {
  padding: 10px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 5px;
  width: 50px;
  margin-bottom: 15px;
  border: 3px solid #005f55;
  height: 50px;
}
.otp_model_main_containner .otp_btn_conatinner {
  width: 100%;
  text-align: end;
}

.otp_model_main_containner .submit-btn,
.otp_model_main_containner .resend-btn {
  padding: 10px 15px;
  border: 1px solid #d9d9d9;
  background-color: #005f55;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin: 10px 5px;
  border-radius: 5px;
  transition: 0.3s;
  width: 100%;
}
.otp_model_main_containner .submit-btn:hover {
  background-color: transparent;
  transition: 0.3s;
  border: 2px solid #005f55;
  color: #000;
  font-weight: 500;
}

.otp_model_main_containner .resend-btn {
  background: transparent;
  margin-top: 5px;
  color: #000;
  border: 1px solid #d9d9d9;
}

.otp_model_main_containner .submit-btn:disabled,
.otp_model_main_containner .resend-btn:disabled {
  background: gray;
  cursor: not-allowed;
}

/* OtpForm.css */
.otp_model_main_containner .otp-inputs {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.otp_model_main_containner .resend_otp_btns {
  transition: 0.5s;
  font-weight: bold;
  cursor: pointer;
  color: #005f55;
  border: none;
  outline: none;
  background-color: transparent;
}
.otp_model_main_containner .resend_otp_btns:hover {
  transition: 0.5s;
  text-decoration: underline;
  color: blue;
}
.otp_model_main_containner p.show_send_email {
  color: #000000;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
}
.otp_model_main_containner p.show_resend_otp_btns_timer {
  margin-bottom: 30px;
  text-align: center;
}
.otp_model_main_containner .otp_logo_containner {
  width: 100%;
  text-align: center;
}
.otp_model_main_containner .otp_logo_containner img {
  width: 215px;
}

@media only screen and (max-width: 380px) {
  .otp_model_main_containner .otp_logo_containner img {
    width: 180px;
  }
}
