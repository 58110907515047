/* body {
  font-family: "Poppins", sans-serif;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
} */

.drag_and_drop_image_containner .upload-container {
  width: 410px;
  height: 150px;
  border: 3px dotted #002c26;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.3s ease;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
}

.drag_and_drop_image_containner .upload-container input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.drag_and_drop_image_containner .drag-text {
  font-size: 16px;
  text-align: center;
  color: #000;
  position: absolute;
  line-height: 1.5;
  z-index: 0;
}

.drag_and_drop_image_containner .preview {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.drag_and_drop_image_containner .preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .drag_and_drop_image_containner .overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  z-index: 1;
} */
.drag_and_drop_image_containner .overlay {
  display: none;
  position: absolute;
  top: -10px;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: red;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  z-index: 1;
  border-radius: 50%;
}
.drag_and_drop_image_containner .upload-container:hover .overlay {
  display: flex;
  animation: fadeInDragAndDrop 0.5s ease forwards;
}

.drag_and_drop_image_containner .overlay .close-btn {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.drag_and_drop_image_containner .remove-text {
  font-size: 14px;
  margin-top: 5px;
  cursor: pointer;
  z-index: 0;
}

.drag_and_drop_image_containner .fade-in {
  animation: fadeInDragAndDrop 0.5s ease forwards;
}

.drag_and_drop_image_containner .fade-out {
  animation: fadeOutDragAndDrop 0.5s ease forwards;
}

@keyframes fadeInDragAndDrop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutDragAndDrop {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.drag_and_drop_image_containner .loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #002c26;
  width: 35px;
  height: 35px;
  animation: dradAndDropSpin 1s linear infinite;
  position: absolute;
  z-index: 1000;
}

@keyframes dradAndDropSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.drag_and_drop_image_containner .bg_profile_img {
  background: #fff;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 400px; */
}
@media only screen and (max-width: 1024px) {
  .add-agent-form-containner {
    display: block;
  }
  /* .drag_and_drop_image_containner .bg_profile_img {
    width: 300px !important;
  } */
}
@media only screen and (max-width: 368px) {
  .drag_and_drop_image_containner .bg_profile_img {
    width: auto !important;
  }
}
