.multiple-image-uploader-containner {
  text-align: center;
}
.multiple-image-uploader-containner .pulse-btn {
  /* width: 300px;
  max-width: 100%;
  height: 100px;
  border-radius: 15px;
  background-color: transparent;
  border: 1px solid #004b40;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; */
  width: 600px;
  max-width: 100%;
  height: 130px;
  border-radius: 15px;
  background-color: transparent;
  border: 2px dotted #004b40;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.multiple-image-uploader-containner .pulse-btn:hover {
  transform: scale(1.03);
  border: 3px dotted #004b40;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.multiple-image-uploader-containner .pulse-btn img {
  color: #fff;
  font-size: 24px;
}

.multiple-image-uploader-containner .pulse-active {
  animation: pulse 1.5s infinite;
}
.multiple-image-uploader-containner .krajee-default {
  position: relative;
  padding: 30px;
}
.multiple-image-uploader-containner .image-patrant {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(61, 171, 136, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(55, 117, 107, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(237, 50, 55, 0);
  }
}

.multiple-image-uploader-containner .file-preview-container {
  margin-top: 20px;
}

.multiple-image-uploader-containner .file-preview-list {
  list-style: none;
  padding: 0;
}

.multiple-image-uploader-containner .file-preview-list li {
  display: inline-block;
  margin-right: 10px;
}

.multiple-image-uploader-containner .file-preview-image {
  width: 100%;
  max-width: 100%;
  height: 300px;
  object-fit: cover;
  /* border-radius: 8px; */
  cursor: pointer;
  margin: 10px;
  /* opacity: 0.6; */
  transform: scale(1.15);
  transition: transform 0.5s, opacity 0.5s;
}
.multiple-image-uploader-containner .file-preview-image:hover {
  opacity: 0.8;
  transform: scale(1.2);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.multiple-image-uploader-containner .kv-file-remove {
  border-radius: 50%;
  z-index: 8;
  right: 0px;
  position: absolute;
  top: 10px;
  text-align: center;
  color: #fff;
  background-color: #ed3237;
  border: 1px solid #ed3237;
  padding: 2px 6px;
  font-size: 11px;
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;
  pointer-events: none;
}

.multiple-image-uploader-containner .krajee-default:hover .kv-file-remove {
  transform: rotate(-90deg);
  opacity: 1;
  pointer-events: auto;
}

.multiple-image-uploader-containner .kv-file-remove.kv-file-download {
  right: 30px;
}
.multiple-image-uploader-containner .kv-file-remove:hover {
  border-color: #fdeff0;
  background-color: #fdeff0;
  color: #ed1924;
}
.multiple-image-uploader-containner .upload-images-button-containner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

/* light box style... */

.lg-outer {
  z-index: 10509 !important;
}
.lg-progress-bar {
  background-color: #fff;
}
.lg-progress-bar .lg-progress {
  background-color: #b59410 !important;
}
/* light box style... */
.multiple_img_size_set {
  width: 100px !important;
  height: 100px !important;
}
.multiple_img_main_default {
  width: auto;
}

@media only screen and (max-width: 450px) {
  .multiple-image-uploader-containner .text-end button.success-btn {
    min-width: 100%;
    text-align: center;
  }
  .multiple-image-uploader-containner .file-preview-image {
    height: 200px;
    margin: 0;
  }
  .multiple-image-uploader-containner .krajee-default {
    padding: 25px 30px;
  }
}
