.login-page .container {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}

.login-page.page-containners-main {
  padding-bottom: 0px !important;
}

.login-page .container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.login-page .forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.login-page .signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 40%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
  overflow: hidden;
}

.login-page form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  border-radius: 10px;
}

.login-page form.sign-up-form {
  opacity: 0;
  z-index: 1;
  margin: 50px;
}

.login-page form.sign-in-form {
  z-index: 2;
  animation: righttoleftin 1s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.login-page form.sign-in-form-animation {
  animation: righttoleftin 1s;
}

.login-page .title {
  font-size: 2rem;
  color: #444;
  margin-bottom: 10px;
  margin-top: 120px;
}

.login-page .input-field {
  max-width: 380px;
  width: 90%;
  background-color: #fff;
  margin: 10px 0;
  height: 45px;
  border-radius: 0;
  /* display: table; */
  padding: 0 0.4rem;
  position: relative;
  padding-left: 0;
  margin-left: 0;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  gap: 10px;
}

.login-page .input-field i {
  text-align: center;
  line-height: 45px;
  color: #000;
  transition: 0.5s;
  font-size: 1.1rem;
  margin: auto 10px;
  margin-left: 0;
  padding-left: 0;
}

.login-page .input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.login-page .input-field input::placeholder {
  /* color: #aaa; */
  font-weight: 500;
}

.login-page .social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.login-page .social-media {
  display: flex;
  justify-content: center;
}

.login-page .social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.login-page .social-icon:hover {
  color: #f86f03;
  border-color: #f86f03;
}
.tab-containner.pt-2 a img {
  background: #fff;
  border-radius: 50px;
  padding: 4px;
  width: 35px !important;
}
.login-page .btn {
  width: 90%;
  background-color: #00352de3;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  max-width: 380px;
}

.login-page .sign-in-btn:hover {
  color: #00352de3;
  border: 1px solid #00352de3;
  border-radius: 4px;
  background-color: transparent;
}
.login-page .sign-in-btn {
  margin-bottom: 70px;
}
.login-page .panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.login-page .container:before {
  content: "";
  position: absolute;
  height: 2500px;
  width: 1800px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #002c26 0%, #004d42 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.login-page .image {
  /* width: 100%; */
  width: 90%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
  margin-top: 80px;
}

.login-page .panel {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  /* justify-content: space-around; */
  text-align: center;
  z-index: 6;
  margin-top: 50px;
  position: relative;
}

.login-page .left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.login-page .right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.login-page .panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
  position: absolute;
  left: -3%;
  top: 0%;
}

.login-page .panel h3 {
  font-weight: 400;
  line-height: 1.4;
  font-size: 1.5rem;
  margin: 0 90px 20px 90px !important;
}

.login-page .panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.login-page .btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.login-page .right-panel .image,
.login-page .right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.login-page .container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.login-page .container.sign-up-mode .left-panel .image,
.login-page .container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.login-page .container.sign-up-mode .signin-signup {
  left: 25%;
}

.login-page .container.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.login-page .container.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}
.tab-containner.pt-2 {
  background: #004d42 !important;
}

.login-page .container.sign-up-mode .right-panel .image,
.login-page .container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.login-page .container.sign-up-mode .left-panel {
  pointer-events: none;
}

.login-page .container.sign-up-mode .right-panel {
  pointer-events: all;
}
.login-page .tab-containner {
  display: flex;
  margin: 0rem 5rem;
  /* overflow: hidden; */
  transition: all 0.2s 0.7s;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  justify-content: space-evenly;
  height: fit-content;
  z-index: 9;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: none;
}
.login-page .tab-containner a {
  border-radius: 5px;
  width: fit-content;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: transparent;
  cursor: pointer !important;
  padding: 10px !important;
  z-index: 9;
  transition: 0.5s;
  position: relative;
}
/* .login-page .tab-containner a:hover {
  transform: scale(1.1);
} */
.login-page .tab-containner .active-tab {
  width: min-content;
  transition: 0.5s;
  animation: bottomtotopY 0.5s;
  background: #fff;
  margin-bottom: 10px !important;
  border-radius: 25px !important;
  padding: 10px !important;
}
.login-page .tab-containner .active-tab span {
  width: fit-content;
  bottom: -60%;
}

.tab-containner a .tooltip-text {
  display: none;
  position: absolute;
  bottom: -40%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #036558;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.tab-containner .active-tab .tooltip-text {
  display: block;
  opacity: 0;
  animation: fadeInTooltip 0.5s ease-in-out forwards;
  animation-delay: 2s;
}

/* Show tooltip when hover */
.tab-containner a:hover .tooltip-text {
  display: block;
  opacity: 1;
  animation: fadeInTooltip 0.5s ease-in-out forwards;
}

/* Tooltip arrow */
.tab-containner a .tooltip-text::after {
  content: "";
  position: absolute;
  top: -30%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #036558 transparent;
}

.tooltip-text {
  display: none;
  position: absolute;
  bottom: -40%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #036558;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.active-tab .tooltip-text.show-tooltip {
  display: block; /* Show when active and tooltip is visible */
  opacity: 1;
}

.py-1.px-2.m-1.active-tab.d-block img {
  background: #004d42 !important;
}
/* body .py-1.px-2.m-1.active-tab.d-block .change-bg-img {
  background: #fff !important;
} */
.select-field {
  max-width: 380px;
  width: 100%;
  background-color: #fff;
  height: 45px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
  border: none;
  outline: navajowhite;
}
.panels-container .content {
  z-index: 9;
}

button#sign-up-btn:hover,
button#sign-in-btn:hover {
  background: #fff;
  color: #000;
}
.login-page .login-page-main-logo-icon {
  position: absolute;
  transform: translate(-20%, -100%);
}
.main-logo-crm {
  width: 90%;
}
.login-page .forget-password-login-page {
  width: 90%;
  text-align: right;
  margin-top: 1rem;
}
/* .login-page .forget-password-login-page p:hover {
  text-decoration: underline;
  cursor: pointer;
} */
.login-page .input-field .password-icon-hide-show {
  position: absolute;
  right: 10px;
}
/* Keyframe animation */
@keyframes fadeInTooltip {
  0% {
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}
.tab-containner {
  animation: righttoleftin 1s;
}
@keyframes righttoleftin {
  from {
    opacity: 0;
    transform: translateX(100%);
    transition: 1.5s;
  }
  to {
    opacity: 1;
    transform: translateX(0%);
    transition: 1.5s;
  }
}

@keyframes bottomtotopY {
  from {
    opacity: 0;
    transform: translateY(100%);
    transition: 0.5s;
  }
  to {
    opacity: 1;
    transform: translateY(0%);
    transition: 0.5s;
  }
}

/* Media quierys.... */
@media (max-width: 991px) {
  .login-page .signin-signup {
    width: 100%;
  }
}
@media (max-width: 870px) {
  .login-page .login-page-main-logo-icon {
    transform: translate(-0%, -500%);
  }
  .login-page .container {
    min-height: 800px;
    height: 100vh;
  }
  .login-page .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .login-page .signin-signup,
  .login-page .container.sign-up-mode .signin-signup {
    left: 50%;
  }

  .login-page .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .login-page .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .login-page .right-panel {
    grid-row: 3 / 4;
  }

  .login-page .left-panel {
    grid-row: 1 / 2;
  }

  .login-page .image {
    /* width: 200px; */
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .login-page .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .login-page .panel h3 {
    font-size: 1.2rem;
  }

  .login-page .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .login-page .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .login-page .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .login-page .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .login-page .container.sign-up-mode .left-panel .image,
  .login-page .container.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .login-page .container.sign-up-mode .right-panel .image,
  .login-page .container.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .login-page .right-panel .image,
  .login-page .right-panel .content {
    transform: translateY(300px);
  }

  .login-page .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  /* .login-page form {
    padding: 0 1.5rem;
  } */

  .login-page .image {
    /* display: none; */
  }
  .login-page .panel .content {
    padding: 0.5rem 1rem;
  }
  .login-page .container {
    padding: 1.5rem;
  }

  .login-page .container:before {
    bottom: 72%;
    left: 50%;
  }

  .login-page .container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}

@media only screen and (max-width: 1024px) {
  .login-page.page-containners-main {
    margin-top: 0px !important;
  }
  .login-page .image {
    margin-top: 0px;
    width: 100%;
  }
  .login-page .panel h3 {
    font-size: 18px;
    margin: 0 90px 10px 90px !important;
  }
  .login-page .btn.transparent {
    width: 100px;
    height: 35px;
    padding: 5px 10px;
  }
  .login-page .signin-signup {
    width: 60%;
  }
  .tab-containner.pt-2 a img {
    width: 30px;
  }
  .tab-containner a .tooltip-text {
    font-size: 10px;
  }
  .login-page .title {
    font-size: 26px;
  }
  .login-page .input-field {
    width: 90%;
  }

  .login-page .btn {
    width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  .login-page .signin-signup {
    width: 100%;
  }
  .login-page .login-page-main-logo-icon {
    /* transform: translate(-90%, -350%); */
    transform: translate(-130%, -350%);
  }
  .login-page .image {
    /* margin-top: 50px; */
    width: 45%;
  }
  .login-page .panel {
    margin-top: 0px !important;
  }
  .main-logo-crm {
    width: 200px;
  }
  .login-page .title {
    font-size: 26px;
  }
  .login-page form {
    margin: 0rem 5rem;
  }
}
@media only screen and (max-width: 668px) {
  .login-page .login-page-main-logo-icon {
    transform: translate(-70%, -300%);
  }
}

@media only screen and (max-width: 468px) {
  .login-page .login-page-main-logo-icon {
    transform: translate(-40%, -400%);
  }
  .main-logo-crm {
    width: 180px;
  }
  .login-page form.sign-in-form,
  .login-page .tab-containner {
    margin: 10px;
    width: 95%;
  }
  .login-page .image {
    width: 75%;
  }
  /* .login-page .input-field {
    width: 100%;
  } */
  .login-page .forget-password-login-page {
    width: 100%;
  }
  .tab-containner.pt-2 a img {
    width: 30px !important;
  }
}
@media only screen and (max-width: 350px) {
  .main-logo-crm {
    width: 170px;
    margin: -3px;
  }
}
.forget-password-title {
  width: 100%;
  text-align: center;
  padding: 20px;
  margin: 0 !important;
  border: 1px solid gray;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #1c4b44;
  color: #fff !important;
}
.input-field button.send-otp-btn {
  position: absolute;
  right: 0;
  outline: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px;
  transition: 0.5s;
}
.input-field button.send-otp-btn:hover {
  transition: 0.5s;
  color: #fff;
  background-color: #002c26;
}
