.custom-select {
  position: relative;
  width: 100%;
  font-family: "memv" !important;
  font-size: 14px !important;
}
.custom-select::placeholder {
  font-size: 12px !important;
}
.custom-select .select-header .clear-icon {
  font-size: 14px;
  font-weight: bold;
  color: #b4aeae;
  /* position: absolute;
  right: 7px;
  top: 12px; */
}

.select-header {
  padding: 5px 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  background: #fff;
  font-size: 14px;
  height: 35px;
}

.select-body {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  background: #fff;
  z-index: 2;
  max-height: 200px;
  overflow-y: auto;
  padding: 6px;
  transition: all 0.3s ease;
  opacity: 0;
  height: 0;
  pointer-events: none;
}

.select-body.open {
  opacity: 1;
  height: auto;
  pointer-events: auto;
}

.select-search {
  width: 96%;
  padding: 5px;
  border: 2px solid #ccc;
  margin: 2%;
  font-size: 14px;
  height: 30px !important;
}

.select-options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select-option-value {
  font-size: 14px;
}

.select-option-value-no-data-option {
  padding: 20px 10px;
  color: #b4aeae;
}

.select-option {
  padding: 10px;
  cursor: pointer;
}

.select-option:hover {
  background: #f0f0f0;
}
.show-search-box-clear-icon {
  position: relative;
}
.show-search-box-clear-icon .is-clean-select-box {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 12px;
  color: #cccccc;
  cursor: pointer;
}
.is-clean-select-box-selected {
  position: absolute;
  right: 25px;
  top: 10px;
  font-size: 13px !important;
  color: #cccccc;
  cursor: pointer;
  padding: 0px 3px 3px 3px;
  z-index: 123;
}

.remove-px {
  font-size: 12px !important;
}
.remove-px img {
  width: 25px;
  height: 25px;
}
.remove-px .hasDatepicker {
  font-size: 12px;
}
.searchbox-parant-main {
  position: relative;
}
.searchbox-parant-main i {
  position: absolute;
  left: 10px;
  top: 13px;
  font-size: 16px;
  color: #004e41;
}
.search_box::placeholder {
  color: #004e41;
  font-size: 16px;
}

.loading {
  font-size: 12px !important;
}
