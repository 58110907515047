.agent-permition-table-main-containner {
  font-family: "memv";
}
.agent-permition-table-main-containner thead tr th {
  background-color: #025347;
  color: #fff;
  font-weight: 500;
  padding: 10px;
}
.agent-permition-table-main-containner thead tr .set-permition-column-with {
  width: 140px !important;
}
.agent-permition-table-main-containner thead tr th {
  font-size: 14px;
}
.agent-permition-table-main-containner input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  margin: 0px;
}
.agent-permition-table-main-containner input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  outline: 1px rgba(255, 255, 255, 0.2) solid;
  transform: translate(-50%, -50%);
  border: 2px solid #00493f;
  background-color: #fff;
  border-radius: 5px;
  transition: 0.5s;
}
.agent-permition-table-main-containner input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  left: 50%;
  background-color: #0a7968;
  outline: 1px rgba(255, 255, 255, 0.2) solid;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 4px 1px #fff;
  border-radius: 5px;
  color: #fff !important;
  transition: 0.5s;
  border: none;
}
.agent-permition-table-main-containner input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 6px;
  height: 10px;
  border: solid;
  border-width: 0 2px 2px 0;
  border-color: inherit;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  color: #fff;
}
.feature__containner {
  max-height: 300px;
  overflow-y: auto;
}
.feature_main_containner {
  display: flex;
  align-items: center;
  gap: 10px;
}
.feature_main_containner .feature_chekboxs {
  width: 15px;
  height: 15px;
}
@media only screen and (min-width: 1024px) {
  .set-default-height-min {
    min-height: 70vh;
  }
}
