.page-title-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin: 20px 0px;
  background-color: #f6f6f6b5;
}

.title-corrent-page-title-single h2 {
  font-family: system-ui;
  font-size: 22px;
}

.page-sub-title-main {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center !important;
}

.title-corrent-page-title {
  color: #000;
  cursor: pointer;
  transition: color 0.5s, text-decoration 0.5s;
  font-size: 12px;
}

.title-corrent-page-title:hover {
  color: #009688;
  transition: 1s;
  text-decoration: none;
}

.title-corrent-page-title-home {
  font-size: 16px !important;
  padding: 0px 5px;
  position: relative;
}

.page-sub-titles {
  font-size: 16px;
  margin: 0 5px;
}

.page-sub-titles:hover {
  color: #009688;
}

.active-color-sub-title {
  color: #004a40;
  font-size: 16px !important;
}

@media only screen and (max-width: 768px) {
  .title-corrent-page-title-single h2 {
    font-size: 18px !important;
  }

  .page-sub-title-main h2 {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 576px) {
  .page-title-main {
    flex-direction: column;
    align-items: flex-start;
  }
  .page-sub-title-main {
    margin-top: 5px;
  }

  .title-corrent-page-title {
    font-size: 10px;
  }

  .title-corrent-page-title-home {
    top: 0px;
    left: 0px;
  }

  .page-sub-titles {
    font-size: 10px;
    margin: 0px;
  }
}
.breadcomp-home-icon {
  width: 30px;
  height: 30px;
  margin: auto 5px;
}
