.main-page-404-containner .page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-page-404-containner .page_404 img {
  width: 100%;
}
.main-page-404-containner .four_zero_four_bg {
  /* background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif); */
  background-image: url("/svg/image-403.gif");
  height: 400px;
  /* height: 75vh; */
  background-position: center;
  background-repeat: no-repeat;
}
.main-page-404-containner .image-parant-404 {
  width: 100%;
  height: 80vh;
  max-height: 100vh;
}
.main-page-404-containner .four_zero_four_bg h1 {
  font-size: 80px;
}

.main-page-404-containner .four_zero_four_bg h3 {
  font-size: 80px;
}

.main-page-404-containner .link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #00433a;
  margin: 20px 0;
  display: inline-block;
}
.main-page-404-containner .contant_box_404 {
  margin-top: -50px;
}
@font-face {
  font-family: "arvo";
  src: url("/font/arvo.ttf");
  font-display: swap;
}
.main-page-404-containner .contant_box_404 * {
  font-family: "arvo" !important;
}
@media only screen and (max-width:768px){
  .main-page-404-containner .page_404 div.w-100{
    display: flex;
    align-items: center;
    height: 90vh;
    justify-content: center;
  }
  .main-page-404-containner .page_404{
    padding: 0;
  }
  .main-page-404-containner .image-parant-404{
    height: auto !important; 
  }
}