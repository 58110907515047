.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  overflow: hidden;
  color: #000;
  position: relative;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
  font-family: "memv" !important;
}

.ag-courses-item_link {
  color: white !important;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}

.ag-courses-item_link:hover .ag-courses-item_title span {
  color: #fff !important;
  /* border-bottom: 1px solid #fff; */
}

.ag-courses-item_link:hover .change-text-color-yellow {
  color: #f9b234 !important;
}

.ag-courses-item_link:hover .change-text-color-green {
  color: #004d42 !important;
}

.ag-courses-item_link:hover .change-text-color-red {
  color: #ff0000 !important;
}

.task-list.closed_lost_main {
  border-color: red;
}
.task-list.closed_Won_main {
  border-color: green;
}
.card_header.closed_Won {
  background-color: green;
}
.task-list.notcontacted_main {
  border-color: #f9b234;
}
.card_header.notcontacted {
  background-color: #f9b234;
}
.task-list.firest_notconect_main {
  border-color: #42a5f5;
}

.task-list {
  border: 2px solid #004d42;
  width: 100%;
  border-radius: 10px;
  min-height: 50vh;
  margin-bottom: 10px;
  overflow: hidden;
}
.task-list .list {
  height: 50vh;
  overflow-y: scroll;
}

.task-list.firest_notconect_main .list::-webkit-scrollbar-thumb {
  background: #42a5f5;
  border-radius: 10px;
}
.task-list.mian_color_box .list::-webkit-scrollbar-thumb,
.dropdown .sub-dropdown::-webkit-scrollbar-thumb,
.ag-courses-item_bg.won {
  background-color: green;
}
.task-list.closed_Won_main .list::-webkit-scrollbar-thumb {
  background-color: green;
  border-radius: 10px;
}
.task-list.closed_lost_main .list::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}

.card_header {
  padding: 15px;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  background: #004d42;
  color: #fff;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}

.card_header.closed_lost {
  background-color: red;
}

.card_header.firest_notconect {
  background-color: #42a5f5;
}

.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;
  border-radius: 50%;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.ag-courses-item_bg.not_conected_box {
  background-color: #42a5f5;
}
.ag-courses-item_bg.meetings_box {
  background-color: #fff;
}

.ag-courses-item_bg.lost {
  background-color: fff;
}

.notcontacted_main.task-list .list::-webkit-scrollbar-thumb {
  background: #f9b234;
  border-radius: 10px;
}

.ag-courses-item_title {
  font-size: 15px !important;
  color: #000;
  z-index: 2;
  position: relative;
}
.ag-courses-item_title,
.ag-courses-item_title span {
  font-family: memv !important;
}

.ag-courses_item {
  margin: 0 15px 0px;

  overflow: hidden;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  border-radius: 10px;
}

.ag-courses_box {
  padding: 10px 0;
}
