@import url(https://fonts.googleapis.com/css?family=Bungee);
@font-face {
  font-family: "memv";
  src: url("/font/memv.woff2");
  font-display: swap;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "memv" !important;
  font-weight: 600;
}
p,
span,
a,
ul li,
label,
button {
  font-family: "memv" !important;
}

a {
  font-size: 0.875rem;
  text-decoration: none;
  color: var(--theme-deafult);
  transition: 0.5s all;
}

a:hover {
  color: var(--theme-deafult);
  transition: all 0.5s all;
}

a:focus-visible {
  outline: none;
}

a[disabled] {
  cursor: not-allowed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
}

* a {
  color: var(--theme-deafult);
  text-decoration: none;
  letter-spacing: 1px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* all-lead css start  */
.all-lead-add-select-box-style {
  /* width: 15% !important; */
}
/* all-lead css end */

.mini-header {
  margin: 0px;
  padding: 0px;
}
.mini-header .text-primary {
  color: #5a8dee !important;
}
.mini-header .btn-primary {
  border-color: #f9f9f9 !important;
  background-color: #f9f9f9 !important;
  color: #004a40;
  padding: 5px 10px !important;
}
.mini-header .navbar {
  border-radius: 5px;
}
.mini-header .sidebar {
  width: 100%;
  /* min-height: 100vh; */
  height: 100%;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #fff;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 1;
  transition: 0.5s;
}
.mini-header .sidebar.active {
  left: 0;
}
.mini-header .sd-header {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  padding: 15px;
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
  color: #fff;
}
.mini-header .sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.mini-header .sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}
.mini-header .sd-body {
  overflow-x: hidden;
  background-color: #fff;
  /* height: 100vh; */
  height: 100%;
  color: #000;
  /* padding-bottom: 200px !important; */
}
.mini-header .sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}
.mini-header .sd-body ul li {
  list-style: none;
  margin-bottom: 8px;
}
.mini-header .sd-body ul li .sd-link {
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  border-radius: 0px;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid #d9d9d9;
}
.mini-header .container-fluid {
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
}
.mini-header .navbar-brand .mini-logo-img,
.sd-header .mini-logo-img {
  width: 200px;
  height: 40px;
}
.mini-header .mini-header-sub-containner {
  position: fixed;
  z-index: 99;
}

.mini-header .sidebar .dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mini-header .sidebar .sub-dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
  animation: slideDown 300ms ease-in-out;
}

.mini-header .sidebar .dropdown-item {
  padding: 0px;
  text-decoration: none;
  color: #000;
}

.mini-header .sidebar .dropdown-item:hover {
  background-color: #f0f0f0;
}

.mini-header .sidebar .dropdown-menu .dropdown-item {
  position: relative;
}

.mini-header .sidebar .dropdown-menu .dropdown-item:hover .sub-dropdown-menu {
  display: block;
}

.mini-header .sidebar .fa-chevron-up {
  margin-left: 5px;
}

.mini-header .sidebar .fa-chevron-down {
  margin-left: 5px;
}
.mini-header .sidebar .worl-parant-menu {
  position: relative;
}
.mini-header .sidebar .worl-parant-menu-chield {
  position: unset;
  top: 45px;
  left: 0px;
  box-shadow: none !important;
  animation: slideDown 300ms ease-in-out;
}
.mini-header .options-works {
  background-color: white !important;
  color: #000 !important;
}
.mini-header .sub-dropdown-menu li {
  margin-bottom: 0px !important;
}
.mini-header .dropdown-item .sd-link {
  border-bottom: 1px solid black;
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 0px !important;
  transition: 0.5s;
  padding-left: 40px !important;
}
.mini-header .dropdown-item .sd-link:hover {
  transition: 0.5s;
  background-color: #d0c7c7b5 !important;
}
.mini-header .add-sub-menu-hover li .options-works:hover {
  background-color: #f0ececb5 !important;
  color: #000 !important;
  transition: 0.5s;
}
.mini-header .add-sub-menu-hover li .options-works {
  background-color: #fff !important;
  color: #000 !important;
  transition: 0.5s;
}
.mini-header-profile-menu ul,
.mini-header-profile-menu ul li,
.mini-header-profile-menu ul a {
  font-size: 12px !important;
}
.menu.active ul li span {
  cursor: pointer;
}
@media only screen and (max-width: 468px) {
  .mini-header .navbar-brand .mini-logo-img,
  .sd-header .mini-logo-img {
    width: 150px;
    height: 30px;
  }
  .mini-header .mini-header .btn-primary {
    padding: 10px;
  }
  .mini-header .search-box.active {
    width: 180px;
  }
}
@media only screen and (max-width: 468px) {
  .mini-header .search-box.active {
    width: 150px;
  }
}
@media only screen and (max-width: 1024px) {
  .action .menu.active {
    right: -15px !important;
    top: 45px !important;
  }
  body .main_head {
    display: none !important;
  }
  .search-box .search-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .search-box {
    width: 30px;
    height: 30px;
  }
  .search-box .search-icon.active {
    height: 29px;
    line-height: 30px;
    width: 30px;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* @keyframes slideDownSunHeader {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.sub-header-sticy {
  animation: slideDownSunHeader 0.55s ease-out;
} */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #004d42;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #01382f;
}
#sidebar-main .navbar-dropdown .sub-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #004d42;
}

.dropdown {
  display: none;
  position: absolute;
  transition-duration: 1s;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-top: 7px;
  min-width: 170px;
}

.navbar-dropdown:hover > .dropdown {
  display: block !important;
  transition-duration: 1.1s;
}

.dropdown a {
  display: block;
  padding: 15px 30px;
  color: #000;
}

.dropdown a:hover {
  color: #fff;
  background-color: #004d42;
}

.dropdown .dropdown {
  display: none;
  position: absolute;
  left: 100%;
  top: -8px;
  transition-duration: 1s;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  max-height: 200px;
  overflow-y: auto;
}

.dropdown:hover > .dropdown {
  display: block !important;
  transition-duration: 1.1s;
}

.notification-counter {
  position: absolute;
  top: -1px;
  background: red;
  width: 15px;
  border-radius: 100px;
  height: 15px;
  text-align: center;
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  left: 12px;
}

.main_head {
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  background-color: #ffffff;
  z-index: 1000;
  width: 100%;
}
.main_head span,
.main_head .sub-dropdown a,
.main_head .dropdown a {
  font-family: "memv" !important;
  font-weight: 600 !important;
  margin-top: auto;
}

.main_head .main_header .list-unstyled {
  grid-gap: 10px;
  gap: 10px;
  width: 50%;
}

.main_header {
  width: 100%;
  align-items: center;
  padding: 22px 15px;
  position: relative;
  width: 100%;
  box-shadow: none;
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
}

.sidebar-links {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
  padding: 0 5px;
  transition: all 0.5s ease;
  white-space: nowrap;
  overflow-x: auto !important;
}

.sidebar-links > li {
  position: unset !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
body .sidebar-links > li {
  position: unset !important;
}

.sidebar-links > li > a {
  display: block;
  color: #2f2f3b;
  /* line-height: 23px; */
  text-decoration: none;
  text-transform: capitalize;
  padding: 5px 15px;
  margin: 0 3px;
  letter-spacing: 0.04em;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.sidebar-links li:last-child .sidebar-link {
  padding-right: 0 !important;
}

.sidebar-links li:first-child .sidebar-link {
  padding-left: 0 !important;
}

.menu {
  position: absolute;
  top: calc(100% + 24px);
  right: 16px;
  width: 200px;
  min-height: 100px;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: 300ms;
  z-index: 1;
}

.menu::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 14px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
  z-index: -1;
}

.menu.active {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.profile {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  cursor: pointer;
  text-align: end;
}

.profile h3 {
  text-align: end;
  line-height: 1;
  margin-bottom: 4px;
  font-weight: 600;
}

.profile p {
  line-height: 1;
  font-size: 14px;
  opacity: 0.6;
}

.profile .img-box {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.profile .img-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu {
  position: absolute;
  top: calc(100% + 24px);
  right: 16px;
  width: 200px;
  min-height: 100px;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: 300ms;
}

.menu::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 14px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
  z-index: -1;
}

.menu.active {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.menu ul {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background: #fff;
}

.menu ul li {
  list-style: none;
}

.menu ul li a {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.menu ul li a i {
  font-size: 1.2em;
}

.action .menu {
  position: absolute;
  right: -10px;
  padding: 10px 20px;
  background-color: #fff;
  width: 200px;
  border-radius: 15px;
  transition: 0.5s;
}

.action .menu.active {
  top: 55px;
  visibility: visible;
  z-index: 99;
  right: -10px;
}

.action .menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 28px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
}

.action .menu h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding-top: 20px;
  font-weight: 500;
  color: #2a3132;
  line-height: 1.2em;
}

.action .menu h3 span {
  font-size: 14px;
  color: #b3b3b3;
  font-weight: normal !important;
}

.action .menu ul li {
  list-style: none;
  padding: 10px 0;
  border-top: 1px solid #fff;
  display: flex;
  grid-column-gap: 15px;
  column-gap: 15px;
  align-items: center;
  vertical-align: middle;
  justify-content: flex-start;
}

.action .menu ul li img {
  max-width: 20px;
  /* margin-right: 10px; */
  opacity: 1;
  transition: 0.5s;
}

.action .menu ul li:hover img {
  opacity: 1;
}

.action .menu ul li a {
  display: inline-block;
  text-decoration: none;
  /* color: #2a3132; */
  font-weight: bold;
  transition: 0.5s;
  font-family: "memv" !important;
}

.action .menu ul li:hover a {
  color: #004d42;
}

.search-box {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.search-box.active {
  width: 350px;
}

.search-box input {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 50px;
  background: #fff;
  outline: none;
  padding: 0 60px 0 20px;
  font-size: 18px;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.search-box input.active {
  opacity: 1;
}

.search-box input::placeholder {
  color: #a6a6a6;
}

.search-box .search-icon {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #004d42;
  cursor: pointer;
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.search-box .search-icon.active {
  right: 2px;
  height: 39px;
  line-height: 40px;
  width: 40px;
  font-size: 14px;
  background: #004d42;
  color: #fff;
  transform: translateY(-50%) rotate(360deg);
}

.search-box .cancel-icon {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.search-box .cancel-icon.active {
  right: -12px;
  transform: translateY(-50%) rotate(360deg);
}

.search-box .search-data {
  text-align: center;
  padding-top: 7px;
  color: #fff;
  font-size: 18px;
  word-wrap: break-word;
}

.search-box .search-data.active {
  display: none;
}

.custom_notifyimage {
  margin-top: -15px !important;
}

.message-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.announcement-bar {
  width: 100%;
  overflow: hidden;

  color: #fff;
  position: relative;
  height: 50px;
}

@media only screen and (max-width: 991px) {
  .dashbord-list-pipeline .col {
    flex: 100% 1 !important;
    width: 100% !important;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1024px) {
  .dashbord-list-pipeline .col {
    flex: 33.33% 1 !important;
    width: 33.33% !important;
    max-width: 33.33% !important;
  }
}
.main_head {
  display: inline !important;
}
nav.sidebar-main {
  position: sticky !important;
  top: -3px;
  background: #fff;
  z-index: 9;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}
span.notification-profile-text {
  font-weight: bold !important;
  color: #000 !important;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.6); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.modal .form-select,
.modal .form-control {
  font-size: 14px !important;
  padding: 7px 5px !important;
  border-radius: 0 !important;
}
.modal .w-33 input::placeholder {
  font-size: 11px !important;
}
.model_inner .modal-main {
  width: 30% !important;
  min-width: 300px;
}
.model_inner .modal-main p {
  font-size: 14px !important;
}
.modal .w-33 {
  width: 33.33% !important;
}
.modal .w-33 p {
  font-size: 12px !important;
}
.modal-main {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 1024px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-x: auto;
  max-height: 90vh;
  scrollbar-width: none;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.display-block .modal-main {
  display: block;
  /* display: flex; */
  animation: modalFadeIn 0.3s ease-in-out forwards;
}

.display-none .modal-main {
  /* display: none; */
  display: block;
  opacity: 0;
  transform: scale(0.8);
  animation: modalFadeOut 0.3s ease-in-out forwards;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.cursor-pointer-add {
  cursor: pointer;
}
.sub-header-sticy {
  position: fixed;
  z-index: 9;
  background-color: #f9f9f9;
  top: 0px;
  color: #f6f6f6b5;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}
.sub-header-sticy-no {
  transition: 0.5s;
}

body {
  max-width: 1980px !important;
  margin: 0 auto !important;
}
.widget-1 .widget-round .bg-round,
.container-fluid.mt-7.px-5.pb-5 {
  z-index: 0 !important;
  position: relative !important;
}

.mini-header .sidebar.active {
  z-index: 999999999 !important;
}
.main_header .logo-img-header {
  width: 200px;
}
.common-status-button-containner .modal-main {
  width: 40%;
  min-width: 300px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.common-status-button-containner .modal-main .sdvds h5 {
  margin: 20px 0 10px 0 !important;
  background-color: transparent;
  color: #000 !important;
}
.common-status-button-containner .commonstatusbutton-img {
  width: 80px;
}
.common-status-button-containner .modal-footer {
  border: none;
  text-align: center;
  display: block;
}
.common-status-button-containner .modal-footer .modal-footer-cancel-btn {
  border: 1px solid black;
  background-color: transparent;
  padding: 10px;
  font-size: 16px;
  width: 100px;
  border-radius: 3px;
}
.common-status-button-containner .modal-footer .modal-footer-confirm-btn {
  border: 1px solid black;
  background-color: #004b44;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  width: 100px;
  border-radius: 3px;
}
@media only screen and (max-width: 1024px) {
  .search-box.active {
    width: 250px;
  }
  .main_header .logo-img-header {
    width: 130px;
  }
  .main_head {
    display: none;
  }
  .mini-header {
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .main_head {
    display: block;
  }
  .mini-header {
    display: none;
  }
}
@media only screen and (max-width: 468px) {
  .main_head .main_header .list-unstyled {
    position: unset;
    grid-gap: 10px;
    gap: 10px;
  }
}

.profile-model-header {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999999999;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  top: 0;
}
.profile-model-header .containner {
  width: 100%;
  height: 100vh;
  z-index: 9999;
}
.profile-model-header .hide {
  visibility: hidden;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.8) !important;
}

.profile-model-header .close {
  position: absolute;
  right: 0;
  top: 0;
  width: 51px;
  height: 51px;
  font-size: 0;
  cursor: pointer;
}

.profile-model-header .close:before,
.profile-model-header .close:after {
  content: "";
  position: absolute;
  background: #000;
  border-radius: 1px;
  left: 14px;
  right: 14px;
  top: 30px;
  height: 3px;
  transform: rotate(45deg);
}

.profile-model-header .close:after {
  transform: rotate(-45deg);
}

.profile-model-header .popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
  border-radius: 5px;
  width: 600px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.09),
    0px 11px 12px 7px rgba(0, 0, 0, 0.02);
  transition: all 120ms;
  z-index: 99;
  padding: 25px;
  color: #5e5e5e;
}

@media screen and (max-width: 1000px) {
  .profile-model-header .popup {
    width: 80%;
  }
}
@media only screen and (max-width: 430px) {
  .profile-model-header .name_email {
    display: block !important;
  }
  .profile-model-header .name_email input {
    width: 100% !important;
  }
  .profile-model-header .popup-header h1 {
    font-size: 20px !important;
  }
  .profile-model-header .submit_button input {
    font-size: 16px !important;
  }
  .profile-model-header .close {
    width: 45px !important;
    height: 45px !important;
  }
  .profile-model-header .avatar-upload .avatar-preview {
    width: 150px !important;
    height: 150px !important;
  }
  .profile-model-header .avatar-upload {
    display: flex;
    justify-content: center;
  }
}

.profile-model-header .popup-header {
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
}
.profile-model-header .popup-header h1 {
  font-size: 24px;
}

.profile-model-header .popup-body {
  padding: 25px 0;
}

.profile-model-header .button {
  padding: 10px 15px;
  border: 1px solid #f5f5f5;
  text-transform: uppercase;
  box-shadow: 1px 2px 3px #f5f5f5;
  color: #5e5e5e;
  cursor: pointer;
  transition: all 120ms ease-in;
  outline: none;
}

.profile-model-header .button:hover {
  box-shadow: 1px 3px 5px #e9e9e9;
  transform: translateY(-1px);
}

.profile-model-header .avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 20px auto;
}
.profile-model-header .avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}
.profile-model-header .avatar-upload .avatar-edit input {
  display: none;
}
.profile-model-header .avatar-upload .avatar-edit input + label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
}
.profile-model-header .avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.profile-model-header .avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.profile-model-header .name_email {
  display: flex;
  justify-content: space-between;
}

.profile-model-header .name_email input {
  width: 50%;
  border-bottom: 1px solid #000 !important;
  margin: 0 10px;
  border: none;
  padding: 15px 15px 15px 0;
}

.profile-model-header .phone_number input {
  width: 97%;
  border-bottom: 1px solid #000 !important;
  margin: 10px;
  border: none;
  padding: 15px 15px 15px 0;
}

.profile-model-header .submit_button input {
  background: #004d42;
  padding: 10px;
  color: #fff;
  font-size: 20px;
  border: 2px solid #004d42;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 3px;
}

.profile-model-header .submit_button input:hover {
  background: #fff;
  border-color: #004d42;
  color: #004d42 !important;
}
.input-field-popup,
.input-field-popup-email {
  position: relative;
  /* width: 100% !important; */
  grid-gap: 20px;
  gap: 20px;
}
.input-field-popup input,
.input-field-popup-email input {
  width: 100% !important;
  padding-left: 20px !important;
  outline: none;
}
.input-field-popup .user_name_imgs,
.input-field-popup-email .user_email_imgs {
  position: absolute;
  top: 20px;
  left: 5px;
}
.phone_number {
  position: relative;
  grid-gap: 20px !important;
  gap: 20px !important;
}
.phone_number .phone_number_input {
  width: 100% !important;
  padding-left: 20px !important;
  outline: none;
}
.phone_number .phone_number_img {
  position: absolute;
  top: 30px;
  left: 5px;
}
.phone_number .password-icon-hide-show {
  position: absolute;
  top: 30px;
  right: 0px;
}
.name_email .password-icon-hide-show {
  position: absolute;
  top: 20px;
  right: 0px;
}

.footer {
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  padding: 15px;
  transition: 0.5s;
  position: sticky;
  bottom: 0px;
}

footer p {
  color: #fff;
}

footer {
  margin: 0 !important;
  width: 100%;
  z-index: 9999;
}

.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  overflow: hidden;
  color: #000;
  position: relative;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
  font-family: "memv" !important;
}

.ag-courses-item_link {
  color: white !important;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
  transform: scale(10);
}

.ag-courses-item_link:hover .ag-courses-item_title span {
  color: #fff !important;
  /* border-bottom: 1px solid #fff; */
}

.ag-courses-item_link:hover .change-text-color-yellow {
  color: #f9b234 !important;
}

.ag-courses-item_link:hover .change-text-color-green {
  color: #004d42 !important;
}

.ag-courses-item_link:hover .change-text-color-red {
  color: #ff0000 !important;
}

.task-list.closed_lost_main {
  border-color: red;
}
.task-list.closed_Won_main {
  border-color: green;
}
.card_header.closed_Won {
  background-color: green;
}
.task-list.notcontacted_main {
  border-color: #f9b234;
}
.card_header.notcontacted {
  background-color: #f9b234;
}
.task-list.firest_notconect_main {
  border-color: #42a5f5;
}

.task-list {
  border: 2px solid #004d42;
  width: 100%;
  border-radius: 10px;
  min-height: 50vh;
  margin-bottom: 10px;
  overflow: hidden;
}
.task-list .list {
  height: 50vh;
  overflow-y: scroll;
}

.task-list.firest_notconect_main .list::-webkit-scrollbar-thumb {
  background: #42a5f5;
  border-radius: 10px;
}
.task-list.mian_color_box .list::-webkit-scrollbar-thumb,
.dropdown .sub-dropdown::-webkit-scrollbar-thumb,
.ag-courses-item_bg.won {
  background-color: green;
}
.task-list.closed_Won_main .list::-webkit-scrollbar-thumb {
  background-color: green;
  border-radius: 10px;
}
.task-list.closed_lost_main .list::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}

.card_header {
  padding: 15px;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  background: #004d42;
  color: #fff;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}

.card_header.closed_lost {
  background-color: red;
}

.card_header.firest_notconect {
  background-color: #42a5f5;
}

.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;
  border-radius: 50%;
  transition: all 0.5s ease;
}

.ag-courses-item_bg.not_conected_box {
  background-color: #42a5f5;
}
.ag-courses-item_bg.meetings_box {
  background-color: #fff;
}

.ag-courses-item_bg.lost {
  background-color: fff;
}

.notcontacted_main.task-list .list::-webkit-scrollbar-thumb {
  background: #f9b234;
  border-radius: 10px;
}

.ag-courses-item_title {
  font-size: 15px !important;
  color: #000;
  z-index: 2;
  position: relative;
}
.ag-courses-item_title,
.ag-courses-item_title span {
  font-family: memv !important;
}

.ag-courses_item {
  margin: 0 15px 0px;

  overflow: hidden;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  border-radius: 10px;
}

.ag-courses_box {
  padding: 10px 0;
}

.profile-box {
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
  color: #fff;
  position: relative !important;
  overflow: hidden;
}

.profile-box .cartoon {
  position: absolute;
  bottom: -20%;
  animation: bounce-effect 5s infinite ease-in;
  right: -5px !important;
  text-align: right;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}
.profile-box .cartoon img {
  max-width: 250px;
}

[dir="rtl"] .profile-box .cartoon {
  right: unset !important;
  left: 5px;
}

@keyframes bounce-effect {
  0% {
    transform: translateY(0);
    transition: 0.5s;
  }

  50% {
    transform: translateY(-5px);
    transition: 0.5s;
  }

  100% {
    transform: translateY(0);
    transition: 0.5s;
  }
}

@media (max-width: 1660px) {
  .profile-box .cartoon {
    right: 30px;
    text-align: right;
  }

  [dir="rtl"] .profile-box .cartoon {
    left: -6px;
    text-align: left;
  }

  .profile-box .cartoon img {
    /* width: 80%; */
  }
}

@media (max-width: 1500px) {
  .profile-box .cartoon img {
    /* width: 60%; */
  }
}

@media (max-width: 767px) {
  .profile-box .cartoon {
    right: 10px;
  }

  [dir="rtl"] .profile-box .cartoon {
    left: -10px;
  }

  .profile-box .cartoon img {
    /* width: 52%; */
  }
}

@media (max-width: 575px) {
  .profile-box .cartoon {
    right: 30px;
  }

  [dir="rtl"] .profile-box .cartoon {
    left: 10px;
  }

  .profile-box .cartoon img {
    /* width: 48%; */
  }
}
@media (max-width: 435px) {
  .profile-box .cartoon img {
    width: 200px;
  }
}

.profile-box .greeting-user p {
  width: 60%;
}

@media (max-width: 1500px) {
  .profile-box .greeting-user p {
    width: 70%;
  }
}

@media (max-width: 1199px) {
  .profile-box .greeting-user p {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .profile-box .greeting-user p {
    width: 98%;
  }
}

@media (max-width: 600px) {
  .profile-box .greeting-user p {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .profile-box .greeting-user p {
    width: 98%;
  }
  .profile-box .cartoon {
    bottom: -15%;
  }
}

.profile-box .whatsnew-btn {
  margin-top: 3.5rem;
}

@media (max-width: 1500px) {
  .profile-box .whatsnew-btn {
    margin-top: 1.7rem;
  }
}

@media (max-width: 991px) {
  .profile-box .whatsnew-btn {
    margin-top: 3.5rem;
  }
}

@media (max-width: 638px) {
  .profile-box .whatsnew-btn {
    margin-top: 2rem;
  }
}

@media (max-width: 767px) {
  .profile-box .whatsnew-btn .btn {
    padding: 6px 10px;
  }
}

.widget-1 {
  background-size: cover;
  margin-bottom: 25px;
}

.widget-1 i {
  font-weight: 700;
  font-size: 12px;
}

.widget-1 .f-w-500 {
  display: flex;
  align-items: center;
}

@media (max-width: 1580px) and (min-width: 1200px) {
  .widget-1 .f-w-500 {
    display: none;
  }
}

.widget-1 .card-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 18px 25px;
}

@media (max-width: 1720px) {
  .widget-1 .card-body {
    padding: 18px;
  }
}

.widget-1 .widget-round {
  position: relative;
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-radius: 100%;
}

.widget-1 .widget-round .bg-round {
  width: 56px;
  height: 56px;
  box-shadow: 1px 2px 21px -2px rgba(214, 214, 227, 0.83);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: 6px;
  position: relative;
  z-index: 1;
}

.widget-1 .widget-round .bg-round svg {
  width: 24px;
  height: 24px;
}

.widget-1 .widget-round .bg-round .half-circle {
  height: 52px;
  position: absolute;
  left: -9px;
  width: 35px;
  bottom: -8px;
  background: #fff;
  z-index: -1;
}

@media (max-width: 1600px) {
  .widget-1 .widget-round .bg-round {
    width: 40px;
    height: 40px;
  }

  .widget-1 .widget-round .bg-round svg {
    width: 22px;
    height: 22px;
  }

  .widget-1 .widget-round .bg-round .half-circle {
    height: 40px;
    left: -10px;
    width: 30px;
    bottom: -8px;
  }
}

.widget-1 .widget-round.primary {
  border-color: #004d42;
}

.widget-1 .widget-round.secondary {
  border-color: #004d42;
}

.widget-1 .widget-round.success {
  border-color: #54ba4a;
}

.widget-1 .widget-round.warning {
  border-color: #ffaa05;
}

.widget-1 .widget-content {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}

@media (max-width: 1600px) {
  .widget-1 .widget-content {
    grid-gap: 10px;
    gap: 10px;
  }
}

.widget-1 .widget-content h4 {
  margin-bottom: 4px;
}

.widget-1:hover {
  transform: translateY(-5px);
  transition: 0.5s;
}

.widget-1:hover .widget-round .svg-fill:not(.half-circle) {
  animation: tada 1.5s ease infinite;
}

#hour,
#minute,
#second {
  transform-origin: 300px 300px;
  transition: transform 0.5s ease-in-out;
}

.hour-marks {
  fill: none;
  stroke: #52526c;
  stroke-width: 9;
  stroke-miterlimit: 10;
}

.hour-hand {
  fill: none;
  stroke: #ff7e00;
  stroke-width: 17;
  stroke-miterlimit: 10;
}

.sizing-box {
  fill: none;
}

.clockbox {
  margin-bottom: -5px;
}

.clockbox svg {
  width: 45px;
  height: 45px;
}

@media (max-width: 767px) {
  .clockbox svg {
    height: 36px;
  }
}

.clockbox,
#clock {
  width: 100%;
}

.circle {
  fill: #fff;
  stroke: rgba(255, 255, 255, 0.4);
  stroke-width: 80px;
  stroke-miterlimit: 10;
}

.mid-circle {
  fill: #000;
}

.minute-hand {
  fill: none;
  stroke: #ff7e00;
  stroke-width: 11;
  stroke-miterlimit: 10;
}

.second-hand {
  fill: none;
  stroke: #ffaa05;
  stroke-width: 15px;
  stroke-miterlimit: 10;
}

.card {
  margin-bottom: 30px;
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 15px;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}

.card:hover {
  box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
  transition: all 0.3s ease;
}

.custom_text_date {
  width: 90% !important;
}

.card-body.main_card_body {
  padding: 70px 30px !important;
  /* padding: 65px 30px !important; */
}
.profile_show_name_containner {
  line-height: 20px;
}
.profile_show_name_containner span {
  color: #fff;
  font-size: 14px;
  font-weight: normal !important;
}
.profile_show_name_containner span.admin-name {
  font-weight: normal !important;
  font-size: 12px;
}
.profile .img-profile-header {
  width: 45px;
  padding: 5px;
  background: #fff;
  border-radius: 50px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .dashbord-animation-container-main {
    width: 99% !important;
  }
  .ag-courses-item_link:hover,
  .ag-courses-item_link:hover .ag-courses-item_title span {
    color: #000 !important;
  }
}
/* --------------- */

.whatch-main-containner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin: 0;
}

.whatch-main-containner canvas {
  /* border: 0px solid #121212; */
  /* border-radius: 50%; */
  width: 200px;
  position: absolute;
  /* top: -15px;
  right: -50px; */
  top: 20px;
  right: -85px;
}
.whatch-main-containner p {
  position: absolute;
  right: 40px;
  top: 90px;
}

.custom-select {
  position: relative;
  width: 100%;
  font-family: "memv" !important;
  font-size: 14px !important;
}
.custom-select::placeholder {
  font-size: 12px !important;
}
.custom-select .select-header .clear-icon {
  font-size: 14px;
  font-weight: bold;
  color: #b4aeae;
  /* position: absolute;
  right: 7px;
  top: 12px; */
}

.select-header {
  padding: 5px 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  background: #fff;
  font-size: 14px;
  height: 35px;
}

.select-body {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  background: #fff;
  z-index: 2;
  max-height: 200px;
  overflow-y: auto;
  padding: 6px;
  transition: all 0.3s ease;
  opacity: 0;
  height: 0;
  pointer-events: none;
}

.select-body.open {
  opacity: 1;
  height: auto;
  pointer-events: auto;
}

.select-search {
  width: 96%;
  padding: 5px;
  border: 2px solid #ccc;
  margin: 2%;
  font-size: 14px;
  height: 30px !important;
}

.select-options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select-option-value {
  font-size: 14px;
}

.select-option-value-no-data-option {
  padding: 20px 10px;
  color: #b4aeae;
}

.select-option {
  padding: 10px;
  cursor: pointer;
}

.select-option:hover {
  background: #f0f0f0;
}
.show-search-box-clear-icon {
  position: relative;
}
.show-search-box-clear-icon .is-clean-select-box {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 12px;
  color: #cccccc;
  cursor: pointer;
}
.is-clean-select-box-selected {
  position: absolute;
  right: 25px;
  top: 10px;
  font-size: 13px !important;
  color: #cccccc;
  cursor: pointer;
  padding: 0px 3px 3px 3px;
  z-index: 123;
}

.remove-px {
  font-size: 12px !important;
}
.remove-px img {
  width: 25px;
  height: 25px;
}
.remove-px .hasDatepicker {
  font-size: 12px;
}
.searchbox-parant-main {
  position: relative;
}
.searchbox-parant-main i {
  position: absolute;
  left: 10px;
  top: 13px;
  font-size: 16px;
  color: #004e41;
}
.search_box::placeholder {
  color: #004e41;
  font-size: 16px;
}

.loading {
  font-size: 12px !important;
}

/* customDatePickerStyle.css */

.react-date-picker {
  border: 1px solid #ccc;
  border-radius: 0px;
  padding: 0px;
  /* width: 13.5% !important; */
  height: 36px !important;
}

.react-date-picker__wrapper {
  display: flex;
  align-items: center;
  border: none !important;
  font-size: 14px;
}

.react-date-picker__wrapper .react-date-picker__inputGroup {
  display: flex !important;
  align-items: center !important;
  padding: 0 0 0 30px;
}

.react-date-picker__inputGroup__input {
  border: none;
  outline: none;
  padding: 5px;
  /* width: 20px !important; */
}

.react-date-picker__calendar {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.react-date-picker__button {
  background: none;
  border: none;
  cursor: pointer;
}

.react-date-picker__clear-button__icon,
.react-date-picker__calendar-button__icon {
  width: 16px;
  color: gray;
}

.react-date-picker__calendar-button {
  position: absolute;
  left: 0px;
}
.react-calendar__month-view__days__day--weekend {
  color: black;
}
.react-date-picker__calendar {
  z-index: 9 !important;
}
.react-calendar__tile--active {
  background: #004e41 !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}

/* DATE RANGE PICKER STYLE START...... */
.date_range_picker_containner {
  position: relative;
}
.date_range_picker_containner input {
  padding: 5px 5px 5px 35px !important;
  border-radius: 0;
  border: 1px solid #d9d9d9;
}
.date_range_picker_containner img {
  position: absolute;
  left: 10px;
  width: 20px;
  top: 10px;
}
/* DATE RANGE PICKER STYLE END...... */

.lead-detail-container {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  margin: 50px 10px;
  padding: 20px 20px 30px 20px !important;
  border: 1px solid #000;
}
.lead-detail-container .lead-detail-container-child {
  width: 33%;
  margin: 0px 0px 0px 30px;
  text-align: center;
}
.remove-ml-lead-details {
  margin-left: 0px !important;
}
.lead-detail-container .lead-detail-container-child .lead-data-key-value {
  text-align: center !important;
  color: black;
  font-size: 14px;
}
.lead-detail-container .lead-detail-container-child .lead-data-key-value-data {
  text-align: center !important;
  color: black;
  padding: 0 5px;
  font-size: 14px;
}
.lead-detail-container .lead-detail-container-child-data {
  /* border-bottom: 1px solid #cccccc; */
  border-bottom: 1px solid #efefef;
  line-height: 20px;
}

.note-containner-lead-list {
  width: 33%;
  border: none !important;
}

.lead-detail-history-btn {
  justify-content: space-between;
}

.lead-detail-history-btn button {
  border: 1px solid gray;
  border-radius: 4px;
  float: inline-end;
  display: block;
  padding: 5px 10px;
  background-color: transparent;
  transition: 0.5s;
  cursor: pointer;
  margin: 8px 0 0px 0;
  width: 100px;
  height: 40px;
  color: #000;
  /* border: none; */
  font-size: 16px;
  font-weight: 600;
}
.lead-detail-history-btn button:hover {
  background: linear-gradient(132deg, #004e41, #004e41, #004e41);
  color: #fff;
  /* color: #004e41; */
  /* color: #4d32c0; */
  transition: 0.5s;
}
.lead-detail-history-btn h6 {
  margin: 20px 0 10px 0;
  font-size: 16px;
  text-align: start !important;
}
.note-text-area-containner {
  position: relative;
}
.note-text-area-containner textarea {
  width: 100%;
  padding: 5px 10px;
  height: 150px;
  color: #000;
  z-index: 9;
  border-radius: 5px;
  border: 3px solid #004d42;
}
.lead-note-details-btn-containner {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.lead-list-cancel-btn {
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
}
.lead-list-cancel-btn img {
  width: 25px;
}
.lead-list-submit-btn img {
  width: 30px;
}

.lead-list-submit-btn {
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: 0.5s;
  background: transparent;
}
.lead-list-submit-btn:hover {
  color: black;
  transition: 0.5s;
}
.note-chat-containner {
  border: 1px solid black;
  margin-top: 10px;
}
.note-chat-containner ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: auto;
  height: 180px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.note-chat-containner ul li {
  text-align: right;
  padding: 10px;
  margin: 0px !important;
}
.note-chat-containner ul li .entete {
  margin-bottom: 5px;
}
.note-chat-containner ul li h3 {
  color: #bbb;
  font-size: 12px;
}
.note-chat-containner ul li h2 {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
}
.note-chat-containner ul li .triangle {
  border-color: transparent transparent #6fbced transparent;
  /* margin-left: 375px; */
  margin-left: 960px;
}
.note-chat-containner ul li .messages {
  padding: 5px 20px;
  color: #fff;
  line-height: 25px;
  max-width: 90%;
  display: inline-block;
  text-align: left;
  border-radius: 5px;
  background-color: #004e41;
}
.cansel-submit-note-btn {
  margin-top: 10px;
  text-align: left;
}
.cansel-btn-note-chat {
  border: 1px solid black;
  padding: 5px 10px;
  background-color: transparent;
  margin: 0 10px 0 0;
}
.submit-btn-note-chat {
  border: 1px solid black;
  padding: 5px 10px;
  background-color: #004e41;
  margin: 0 10px 0 0;
  color: #fff;
  transition: 0.5s;
}
.submit-btn-note-chat:hover {
  background-color: #fff;
  color: #000;
  transition: 0.5s;
}

/* list page style start */
.unit-number-list {
  height: 36px !important;
  outline: none;
  border: 1px solid #cccccc !important;
}
.unit-number-list::placeholder {
  font-size: 14px !important;
}
/* list page style End */

/* Property finder leads style start */
.property-finder-leads-containner .searchbox-parant-main {
  float: right;
}
/* Property finder leads style end */

/* bayut leads style start */
.bayut-lead-list-containner .custom-select {
  width: 200px !important;
  margin: 0 10px 0 0;
}
.link-bayut-leads-data-table {
  color: blue;
  cursor: pointer;
  font-size: 11px;
  padding: 0 2px;
}
.link-bayut-leads-data-table:hover {
  color: #4d32c0;
  text-decoration: underline;
}
/* bayut leads style end */

/* Old Lead Pade style start */
.show-old-lead-action-icon-main {
  grid-gap: 5px;
  gap: 5px;
  display: flex;
}
.show-old-lead-action-icon-main .show-old-lead-action-icon {
  padding: 7px;
  /* background-color: #004e41; */
  color: white;
  font-size: 14px;
  cursor: pointer;
}
.line-height-add {
  line-height: 18px;
}
.add-width-old-details-page-list {
  width: 25% !important;
  padding: 0px 10px;
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.add-shadow-old-details {
  box-shadow: 0px 3px 20px #f2f2f2 !important;
}

.add-style-details-box {
  background: #00463c21;
  border-radius: 8px;
  padding: 10px;
  /* margin: 0 0 20px 0; */
  /* display: flex; */
  align-items: center;
  min-height: 70px;
  width: 24% !important;
  flex-wrap: wrap;
}
.notes-lead-details-page {
  width: 74% !important;
}
.notes-lead-details-pagess {
  width: 49% !important;
}
.card-containners {
  grid-gap: 1.3%;
  gap: 1.3%;
}
/* .add-all-deals .select-header,
.edit-old-lead-detail .select-header {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 8px;
} */

.add-all-deals .form-control,
.edit-old-lead-detail .form-control {
  font-size: 12px;
  padding: 10px !important;
}
.add-all-deals .form-control::placeholder,
.edit-old-lead-detail .form-control::placeholder {
  font-size: 12px !important;
}

.add-all-deals .row div p,
.edit-old-lead-detail .row div p {
  font-size: 12px;
}
.add-all-deals .all-lead-add-select-box-style,
.edit-old-lead-detail .all-lead-add-select-box-style {
  width: 100% !important;
}
.edit-old-lead-detail .all-lead-add-select-box-style li {
  width: 100%;
}
.add-all-deals .select-options .select-option:hover {
  width: auto;
}
.radio-button-add-all-deals {
  width: 15px;
  height: 15px;
}
.radio-button-add-all-deals .radio-button-add-all-deals-text {
  font-size: 12px;
}
.add-all-deals .react-date-picker {
  width: 100% !important;
}
.add-all-deals .add-all-deals-checkbox {
  width: 10%;
  margin: 5px 0 0 0;
}
.select-old-deals-btn {
  float: inline-end;
  padding: 10px 5px;
  border: 1px solid black;
  border-radius: 3px;
  background-color: #fff;
  transition: 0.5s;
}
.select-old-deals-btn:hover {
  background-color: #004d42;
  color: #fff;
  transition: 0.5s;
}
.add-all-deals .model_inner .modal-main {
  height: 60%;
}
.add-all-deals .close-button-model {
  position: absolute;
  bottom: 20px;
  right: 15px;
}
.all-list-main-containner .react-date-picker,
.all-list-main-containner .unit-number-list {
  width: 100% !important;
}
.olad-lead-card-main-containner {
  box-shadow: 0px 3px 20px #f2f2f2 !important;
  border-radius: 10px;
}
.olad-lead-card-main-containner .olad-lead-card {
  margin: 10px;
}
.olad-lead-card-main-containner .old-lead-details-data {
  background: #00463c21;
  border-radius: 8px;
  padding: 10px;
  /* margin: 0 0 20px 0; */
  /* display: flex; */
  align-items: center;
  transition: all 0.3s ease;
}
.olad-lead-card-main-containner .old-lead-details-data:hover {
  transform: translateY(-5px);
  transition: all 0.3s ease;
}
.page-containners-main {
  padding-bottom: 65px !important;
}
.container .row p {
  padding: 5px 0px;
}
.form-control {
  padding: 5px 10px !important;
}
.add-agent-form-containner {
  display: flex;
}
.chat_containner_box {
  padding: 10px;
  margin: 10px 0px;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  /* background-color: #f3f3f3; */
  background-color: #fff;
}
.chat_containner_box .chat_containner_box_main {
  width: 100%;
  margin: 10px 0;
}
.chat_containner_box .chat_containner_box_main_right {
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.chat_containner_box .chat_containner_box_chield {
  width: 100%;
  position: relative;
  justify-content: center;
}
.chat_containner_box .align-right .chat_containner_box_chield {
  justify-content: end;
}
.chat_containner_box .align-left .chat_containner_box_chield {
  justify-content: start;
}
.chat_containner_box .chat_image_containner {
  padding: 10px;
  display: flex;
  align-items: end;
}
.chat_containner_box .align-left .chat_image_containner {
  align-items: start;
}
.chat_containner_box .chat_image_containner img {
  width: 40px;
  height: 40px;
}
.chat_containner_box .chat_message_box_containner {
  border: 1px solid #d9d9d9;
  padding: 5px 15px;
  /* width: 100%; */
  text-align: start;
  border-radius: 10px;
  border-top-left-radius: 0px;
}
.chat_containner_box .chat_message_box_containner span {
  display: block;
}
.chat_containner_box .chat_message_box_containner .message_messages {
  font-size: 14px;
}
.chat_containner_box .chat_message_box_containner .message_type {
  font-size: 14px;
  word-wrap: break-word;
  min-width: 100px;
}
.chat_containner_box .chat_message_box_containner .message_time {
  font-size: 12px;
  color: gray;
  text-align: end;
}

.chat_containner_box .align-left .chat_message_box_containner:before {
  content: "";
  top: 20px;
  left: 52px;
  position: absolute;
  background: transparent;
  height: 0px;
  width: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #005448;
}
.chat_containner_box
  .chat_containner_box_main_right
  .chat_message_box_containner {
  border-radius: 10px;
  border-top-right-radius: 0px !important;
}
.chat_containner_box .align-right .chat_message_box_containner:before {
  content: "";
  bottom: 20px;
  right: 51px !important;
  position: absolute;
  background: transparent;
  height: 0px;
  width: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #005448;
}

.chat_containner_box .align-left {
  justify-content: flex-start;
}

.chat_containner_box .align-right {
  justify-content: flex-end;
}

.chat_containner_box .chat_image_containner img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat_containner_box .chat_message_box_containner {
  max-width: 70%;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  background-color: #005448;
  color: #fff;
}

/* Old Lead Pade style end */

@media only screen and (max-width: 400px) {
  .success-btn,
  .cancel-btn {
    width: 100px !important;
  }
}
@media only screen and (max-width: 575px) {
  .datatable-header-main {
    display: block !important;
  }
  .datatable-header-main .filter_button {
    margin: 5px 0px;
    width: 100%;
    /* height: auto !important; */
  }
  .datatable-header-main .search_box {
    margin: 5px 0px;
    width: 100%;
  }
  .searchbox-parant-main i {
    top: 19px;
  }
  .data-table-single-search-input-box-container {
    margin: 20px 12px !important;
  }
  .data-table-single-search-input-box-container input {
    width: 100% !important;
  }
  .data-table-single-search-input-box-container i {
    left: 10px;
  }
}
@media only screen and (max-width: 700px) {
  .lead-detail-container {
    display: block !important;
  }
  .lead-detail-container .lead-detail-container-child {
    width: 100%;
    margin: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .dashbord-date-filter-btns {
    display: block !important;
  }
  .dashbord-date-filter-btns div,
  .dashbord-date-filter-btns div button {
    width: 100%;
  }
  .dashbord-date-filter-btns div button {
    margin: 5px 0;
  }
}
@media only screen and (max-width: 1024px) {
  .page-containners-main {
    margin-top: 50px !important;
    padding-bottom: 50px !important;
  }
}

.otp_model_main_containner .otp-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.otp_model_main_containner .otp-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 450px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  animation: slideInOtp 0.4s ease-out;
}

.otp_model_main_containner .close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 26px;
  cursor: pointer;
}

@keyframes slideInOtp {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* --------------------------------------------------- */

/* OtpForm.css */
.otp_model_main_containner .otp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -30px;
}

.otp_model_main_containner .otp-input {
  padding: 10px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 5px;
  width: 50px;
  margin-bottom: 15px;
  border: 3px solid #005f55;
  height: 50px;
}
.otp_model_main_containner .otp_btn_conatinner {
  width: 100%;
  text-align: end;
}

.otp_model_main_containner .submit-btn,
.otp_model_main_containner .resend-btn {
  padding: 10px 15px;
  border: 1px solid #d9d9d9;
  background-color: #005f55;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin: 10px 5px;
  border-radius: 5px;
  transition: 0.3s;
  width: 100%;
}
.otp_model_main_containner .submit-btn:hover {
  background-color: transparent;
  transition: 0.3s;
  border: 2px solid #005f55;
  color: #000;
  font-weight: 500;
}

.otp_model_main_containner .resend-btn {
  background: transparent;
  margin-top: 5px;
  color: #000;
  border: 1px solid #d9d9d9;
}

.otp_model_main_containner .submit-btn:disabled,
.otp_model_main_containner .resend-btn:disabled {
  background: gray;
  cursor: not-allowed;
}

/* OtpForm.css */
.otp_model_main_containner .otp-inputs {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.otp_model_main_containner .resend_otp_btns {
  transition: 0.5s;
  font-weight: bold;
  cursor: pointer;
  color: #005f55;
  border: none;
  outline: none;
  background-color: transparent;
}
.otp_model_main_containner .resend_otp_btns:hover {
  transition: 0.5s;
  text-decoration: underline;
  color: blue;
}
.otp_model_main_containner p.show_send_email {
  color: #000000;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
}
.otp_model_main_containner p.show_resend_otp_btns_timer {
  margin-bottom: 30px;
  text-align: center;
}
.otp_model_main_containner .otp_logo_containner {
  width: 100%;
  text-align: center;
}
.otp_model_main_containner .otp_logo_containner img {
  width: 215px;
}

@media only screen and (max-width: 380px) {
  .otp_model_main_containner .otp_logo_containner img {
    width: 180px;
  }
}

.page-title-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin: 20px 0px;
  background-color: #f6f6f6b5;
}

.title-corrent-page-title-single h2 {
  font-family: system-ui;
  font-size: 22px;
}

.page-sub-title-main {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center !important;
}

.title-corrent-page-title {
  color: #000;
  cursor: pointer;
  transition: color 0.5s, -webkit-text-decoration 0.5s;
  transition: color 0.5s, text-decoration 0.5s;
  transition: color 0.5s, text-decoration 0.5s, -webkit-text-decoration 0.5s;
  font-size: 12px;
}

.title-corrent-page-title:hover {
  color: #009688;
  transition: 1s;
  text-decoration: none;
}

.title-corrent-page-title-home {
  font-size: 16px !important;
  padding: 0px 5px;
  position: relative;
}

.page-sub-titles {
  font-size: 16px;
  margin: 0 5px;
}

.page-sub-titles:hover {
  color: #009688;
}

.active-color-sub-title {
  color: #004a40;
  font-size: 16px !important;
}

@media only screen and (max-width: 768px) {
  .title-corrent-page-title-single h2 {
    font-size: 18px !important;
  }

  .page-sub-title-main h2 {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 576px) {
  .page-title-main {
    flex-direction: column;
    align-items: flex-start;
  }
  .page-sub-title-main {
    margin-top: 5px;
  }

  .title-corrent-page-title {
    font-size: 10px;
  }

  .title-corrent-page-title-home {
    top: 0px;
    left: 0px;
  }

  .page-sub-titles {
    font-size: 10px;
    margin: 0px;
  }
}
.breadcomp-home-icon {
  width: 30px;
  height: 30px;
  margin: auto 5px;
}

.call-lead-details-containner-main {
  padding: 20px;
}
.call-lead-details-containner {
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
  width: 100%;
  display: flex;
  color: #fff;
  min-width: 1000px;
}
.call-lead-details-containner-header {
  width: 25%;
  min-width: 250px;
}
.call-lead-details-containner-header p {
  text-align: center;
  padding: 10px;
  margin: 0px;
  font-size: 14px;
}
.call-lead-details-data-containner {
  width: 100%;
  padding: 20px;
  display: flex;
  min-width: 1000px;
}
.call-lead-details-data-containner-parant {
  border-right: 1px solid gray;
  width: 25%;
  min-width: 250px;
}
.call-lead-details-data-containner-data p {
  font-size: 12px;
}
.search-box-in-filter-main {
  position: relative;
}
.search-box-in-filter-main .search-box-in-filter-main-input {
  padding: 5px 10px 5px 30px;
  outline: none;
  font-size: 14px;
  border: 1px solid #cccccc;
  height: 35px;
}
.search-box-in-filter-main i {
  position: absolute;
  left: 20px;
  top: 10px;
}
.call-lead-details-containners-table {
  /* box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3); */
  overflow-x: auto;
  border: 1px solid #d9d9d9;
  border-top: 0px;
}
.recording-show-details {
  border: 1px solid black;
  border-radius: 20px;
  padding: 5px;
  margin: 5px 10px;
  width: 90%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.recording-show-details i {
  color: #002c26;
}
.details-audio {
  width: 100%;
  font-size: 10px !important;
  border-radius: 20px !important;
  padding: 5px !important;
}
.caller-id-row {
  display: flex;
}
.caller-id-row .radio-btn-caller {
  font-size: 12px;
  width: 15% !important;
  margin: 0px 10px 0 0;
}
.details-audio-data-table {
  border-radius: 20px !important;
  padding: 10px !important;
}
.filtered-call-lead-options {
  justify-content: space-between;
}
.filtered-call-lead-options .select-option-value {
  width: 200px;
}
.data-table-audio-show {
  width: 90%;
}
.show-call-detail-comment-parant {
  overflow-y: auto;
  scrollbar-width: thin;
}
.show-call-detail-comment {
  margin: 10px;
  border-radius: 20px 20px 20px 0px;
  background-color: #e7e7e7;
  padding: 20px;
}
.recording-show-details .loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #004e41;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin-loader 1.5s linear infinite;
  text-align: center;
  display: block;
}

@keyframes spin-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer;
}
.search-box-datatable-search i {
  position: absolute;
  top: 10px;
  left: 20px;
}

.lead-setting-toggle-btn {
  border: 1px solid black;
  border-radius: 3px;
  width: 100px;
  height: 30px;
  background: linear-gradient(to right, #004d42 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 1s ease-out;
}
.lead-setting-toggle-btn-animate {
  color: #fff;
  background: linear-gradient(to right, #004d42 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 1s ease-out;
}
.data-table-single-search-input-box-container {
  margin: 20px 0;
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
}
.data-table-single-search-input-box-container input {
  border: 1px solid #cccccc;
  padding: 5px 5px 5px 30px;
  width: 250px;
  outline: none;
}
.data-table-single-search-input-box-container i {
  position: absolute;
  top: 12px;
  right: 225px;
  color: #656565;
}
@keyframes bgMove {
  from {
    background-position: left bottom;
  }
  to {
    background-position: right bottom;
  }
}

.leads-setting {
  height: 57.4vh;
}
.trash-image {
  width: 25px;
  cursor: pointer;
}

/* Lead setting style end */

/* body {
  font-family: "Poppins", sans-serif;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
} */

.drag_and_drop_image_containner .upload-container {
  width: 410px;
  height: 150px;
  border: 3px dotted #002c26;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.3s ease;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
}

.drag_and_drop_image_containner .upload-container input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.drag_and_drop_image_containner .drag-text {
  font-size: 16px;
  text-align: center;
  color: #000;
  position: absolute;
  line-height: 1.5;
  z-index: 0;
}

.drag_and_drop_image_containner .preview {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.drag_and_drop_image_containner .preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .drag_and_drop_image_containner .overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  z-index: 1;
} */
.drag_and_drop_image_containner .overlay {
  display: none;
  position: absolute;
  top: -10px;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: red;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  z-index: 1;
  border-radius: 50%;
}
.drag_and_drop_image_containner .upload-container:hover .overlay {
  display: flex;
  animation: fadeInDragAndDrop 0.5s ease forwards;
}

.drag_and_drop_image_containner .overlay .close-btn {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.drag_and_drop_image_containner .remove-text {
  font-size: 14px;
  margin-top: 5px;
  cursor: pointer;
  z-index: 0;
}

.drag_and_drop_image_containner .fade-in {
  animation: fadeInDragAndDrop 0.5s ease forwards;
}

.drag_and_drop_image_containner .fade-out {
  animation: fadeOutDragAndDrop 0.5s ease forwards;
}

@keyframes fadeInDragAndDrop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutDragAndDrop {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.drag_and_drop_image_containner .loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #002c26;
  width: 35px;
  height: 35px;
  animation: dradAndDropSpin 1s linear infinite;
  position: absolute;
  z-index: 1000;
}

@keyframes dradAndDropSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.drag_and_drop_image_containner .bg_profile_img {
  background: #fff;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 400px; */
}
@media only screen and (max-width: 1024px) {
  .add-agent-form-containner {
    display: block;
  }
  /* .drag_and_drop_image_containner .bg_profile_img {
    width: 300px !important;
  } */
}
@media only screen and (max-width: 368px) {
  .drag_and_drop_image_containner .bg_profile_img {
    width: auto !important;
  }
}

.multiple-image-uploader-containner {
  text-align: center;
}
.multiple-image-uploader-containner .pulse-btn {
  /* width: 300px;
  max-width: 100%;
  height: 100px;
  border-radius: 15px;
  background-color: transparent;
  border: 1px solid #004b40;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; */
  width: 600px;
  max-width: 100%;
  height: 130px;
  border-radius: 15px;
  background-color: transparent;
  border: 2px dotted #004b40;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.multiple-image-uploader-containner .pulse-btn:hover {
  transform: scale(1.03);
  border: 3px dotted #004b40;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.multiple-image-uploader-containner .pulse-btn img {
  color: #fff;
  font-size: 24px;
}

.multiple-image-uploader-containner .pulse-active {
  animation: pulse 1.5s infinite;
}
.multiple-image-uploader-containner .krajee-default {
  position: relative;
  padding: 30px;
}
.multiple-image-uploader-containner .image-patrant {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(61, 171, 136, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(55, 117, 107, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(237, 50, 55, 0);
  }
}

.multiple-image-uploader-containner .file-preview-container {
  margin-top: 20px;
}

.multiple-image-uploader-containner .file-preview-list {
  list-style: none;
  padding: 0;
}

.multiple-image-uploader-containner .file-preview-list li {
  display: inline-block;
  margin-right: 10px;
}

.multiple-image-uploader-containner .file-preview-image {
  width: 100%;
  max-width: 100%;
  height: 300px;
  object-fit: cover;
  /* border-radius: 8px; */
  cursor: pointer;
  margin: 10px;
  /* opacity: 0.6; */
  transform: scale(1.15);
  transition: transform 0.5s, opacity 0.5s;
}
.multiple-image-uploader-containner .file-preview-image:hover {
  opacity: 0.8;
  transform: scale(1.2);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.multiple-image-uploader-containner .kv-file-remove {
  border-radius: 50%;
  z-index: 8;
  right: 0px;
  position: absolute;
  top: 10px;
  text-align: center;
  color: #fff;
  background-color: #ed3237;
  border: 1px solid #ed3237;
  padding: 2px 6px;
  font-size: 11px;
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;
  pointer-events: none;
}

.multiple-image-uploader-containner .krajee-default:hover .kv-file-remove {
  transform: rotate(-90deg);
  opacity: 1;
  pointer-events: auto;
}

.multiple-image-uploader-containner .kv-file-remove.kv-file-download {
  right: 30px;
}
.multiple-image-uploader-containner .kv-file-remove:hover {
  border-color: #fdeff0;
  background-color: #fdeff0;
  color: #ed1924;
}
.multiple-image-uploader-containner .upload-images-button-containner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

/* light box style... */

.lg-outer {
  z-index: 10509 !important;
}
.lg-progress-bar {
  background-color: #fff;
}
.lg-progress-bar .lg-progress {
  background-color: #b59410 !important;
}
/* light box style... */
.multiple_img_size_set {
  width: 100px !important;
  height: 100px !important;
}
.multiple_img_main_default {
  width: auto;
}

@media only screen and (max-width: 450px) {
  .multiple-image-uploader-containner .text-end button.success-btn {
    min-width: 100%;
    text-align: center;
  }
  .multiple-image-uploader-containner .file-preview-image {
    height: 200px;
    margin: 0;
  }
  .multiple-image-uploader-containner .krajee-default {
    padding: 25px 30px;
  }
}

.add-tenants .row div p,
.add-tenants .row div .form-control,
.add-tenants .row div input::placeholder,
.add-tenants .row div .document-type {
  font-size: 12px;
}
.add-tenants .custom-select,
.add-tenants .all-lead-add-select-box-style,
.add-tenants .all-lead-add-select-box-style li {
  width: 100% !important;
}
.feature-page .select-options,
.feature-page.select-options:hover,
.feature-page .select-option-value,
.feature-page .select-option-value:hover {
  width: 100% !important;
}

/* Header Style Start... */

.sub-dropdown {
  position: relative;
}

.sub-dropdown-content {
  display: none;
  position: absolute;
  left: -87%;
  top: -100%;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.sub-dropdown:hover .sub-dropdown-content {
  display: block;
}
.sub-dropdown:hover .create_sales_offer_main {
  background-color: #004d42;
  color: #fff;
}
.current-img-show {
  width: 200px;
  height: 100px;
  border-radius: 5%;
}
/* Header Style End... */
.filter_button:hover .link-hover {
  color: #fff !important;
}
.add-unit-model-containner .react-date-picker,
.add-agent-main-containner .react-date-picker {
  width: 100% !important;
}
.add-unit-model-containner .all-lead-add-select-box-style,
.add-unit-model-containner .select-option-value,
.add-unit-model-containner .select-header {
  width: 100% !important;
  font-size: 12px;
}
.public-folder-containner .form-control,
.add-agent-main-containner .form-control,
.add-agent-main-containner .form-control::placeholder {
  font-size: 12px !important;
}

.login-page .container {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}

.login-page.page-containners-main {
  padding-bottom: 0px !important;
}

.login-page .container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.login-page .forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.login-page .signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 40%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
  overflow: hidden;
}

.login-page form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  border-radius: 10px;
}

.login-page form.sign-up-form {
  opacity: 0;
  z-index: 1;
  margin: 50px;
}

.login-page form.sign-in-form {
  z-index: 2;
  animation: righttoleftin 1s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.login-page form.sign-in-form-animation {
  animation: righttoleftin 1s;
}

.login-page .title {
  font-size: 2rem;
  color: #444;
  margin-bottom: 10px;
  margin-top: 120px;
}

.login-page .input-field {
  max-width: 380px;
  width: 90%;
  background-color: #fff;
  margin: 10px 0;
  height: 45px;
  border-radius: 0;
  /* display: table; */
  padding: 0 0.4rem;
  position: relative;
  padding-left: 0;
  margin-left: 0;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.login-page .input-field i {
  text-align: center;
  line-height: 45px;
  color: #000;
  transition: 0.5s;
  font-size: 1.1rem;
  margin: auto 10px;
  margin-left: 0;
  padding-left: 0;
}

.login-page .input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.login-page .input-field input::placeholder {
  /* color: #aaa; */
  font-weight: 500;
}

.login-page .social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.login-page .social-media {
  display: flex;
  justify-content: center;
}

.login-page .social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.login-page .social-icon:hover {
  color: #f86f03;
  border-color: #f86f03;
}
.tab-containner.pt-2 a img {
  background: #fff;
  border-radius: 50px;
  padding: 4px;
  width: 35px !important;
}
.login-page .btn {
  width: 90%;
  background-color: #00352de3;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  max-width: 380px;
}

.login-page .sign-in-btn:hover {
  color: #00352de3;
  border: 1px solid #00352de3;
  border-radius: 4px;
  background-color: transparent;
}
.login-page .sign-in-btn {
  margin-bottom: 70px;
}
.login-page .panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.login-page .container:before {
  content: "";
  position: absolute;
  height: 2500px;
  width: 1800px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #002c26 0%, #004d42 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.login-page .image {
  /* width: 100%; */
  width: 90%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
  margin-top: 80px;
}

.login-page .panel {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  /* justify-content: space-around; */
  text-align: center;
  z-index: 6;
  margin-top: 50px;
  position: relative;
}

.login-page .left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.login-page .right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.login-page .panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
  position: absolute;
  left: -3%;
  top: 0%;
}

.login-page .panel h3 {
  font-weight: 400;
  line-height: 1.4;
  font-size: 1.5rem;
  margin: 0 90px 20px 90px !important;
}

.login-page .panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.login-page .btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.login-page .right-panel .image,
.login-page .right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.login-page .container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.login-page .container.sign-up-mode .left-panel .image,
.login-page .container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.login-page .container.sign-up-mode .signin-signup {
  left: 25%;
}

.login-page .container.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.login-page .container.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}
.tab-containner.pt-2 {
  background: #004d42 !important;
}

.login-page .container.sign-up-mode .right-panel .image,
.login-page .container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.login-page .container.sign-up-mode .left-panel {
  pointer-events: none;
}

.login-page .container.sign-up-mode .right-panel {
  pointer-events: all;
}
.login-page .tab-containner {
  display: flex;
  margin: 0rem 5rem;
  /* overflow: hidden; */
  transition: all 0.2s 0.7s;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  justify-content: space-evenly;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 9;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: none;
}
.login-page .tab-containner a {
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: transparent;
  cursor: pointer !important;
  padding: 10px !important;
  z-index: 9;
  transition: 0.5s;
  position: relative;
}
/* .login-page .tab-containner a:hover {
  transform: scale(1.1);
} */
.login-page .tab-containner .active-tab {
  width: min-content;
  transition: 0.5s;
  animation: bottomtotopY 0.5s;
  background: #fff;
  margin-bottom: 10px !important;
  border-radius: 25px !important;
  padding: 10px !important;
}
.login-page .tab-containner .active-tab span {
  width: -moz-fit-content;
  width: fit-content;
  bottom: -60%;
}

.tab-containner a .tooltip-text {
  display: none;
  position: absolute;
  bottom: -40%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #036558;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.tab-containner .active-tab .tooltip-text {
  display: block;
  opacity: 0;
  animation: fadeInTooltip 0.5s ease-in-out forwards;
  animation-delay: 2s;
}

/* Show tooltip when hover */
.tab-containner a:hover .tooltip-text {
  display: block;
  opacity: 1;
  animation: fadeInTooltip 0.5s ease-in-out forwards;
}

/* Tooltip arrow */
.tab-containner a .tooltip-text::after {
  content: "";
  position: absolute;
  top: -30%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #036558 transparent;
}

.tooltip-text {
  display: none;
  position: absolute;
  bottom: -40%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #036558;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.active-tab .tooltip-text.show-tooltip {
  display: block; /* Show when active and tooltip is visible */
  opacity: 1;
}

.py-1.px-2.m-1.active-tab.d-block img {
  background: #004d42 !important;
}
/* body .py-1.px-2.m-1.active-tab.d-block .change-bg-img {
  background: #fff !important;
} */
.select-field {
  max-width: 380px;
  width: 100%;
  background-color: #fff;
  height: 45px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
  border: none;
  outline: navajowhite;
}
.panels-container .content {
  z-index: 9;
}

button#sign-up-btn:hover,
button#sign-in-btn:hover {
  background: #fff;
  color: #000;
}
.login-page .login-page-main-logo-icon {
  position: absolute;
  transform: translate(-20%, -100%);
}
.main-logo-crm {
  width: 90%;
}
.login-page .forget-password-login-page {
  width: 90%;
  text-align: right;
  margin-top: 1rem;
}
/* .login-page .forget-password-login-page p:hover {
  text-decoration: underline;
  cursor: pointer;
} */
.login-page .input-field .password-icon-hide-show {
  position: absolute;
  right: 10px;
}
/* Keyframe animation */
@keyframes fadeInTooltip {
  0% {
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}
.tab-containner {
  animation: righttoleftin 1s;
}
@keyframes righttoleftin {
  from {
    opacity: 0;
    transform: translateX(100%);
    transition: 1.5s;
  }
  to {
    opacity: 1;
    transform: translateX(0%);
    transition: 1.5s;
  }
}

@keyframes bottomtotopY {
  from {
    opacity: 0;
    transform: translateY(100%);
    transition: 0.5s;
  }
  to {
    opacity: 1;
    transform: translateY(0%);
    transition: 0.5s;
  }
}

/* Media quierys.... */
@media (max-width: 991px) {
  .login-page .signin-signup {
    width: 100%;
  }
}
@media (max-width: 870px) {
  .login-page .login-page-main-logo-icon {
    transform: translate(-0%, -500%);
  }
  .login-page .container {
    min-height: 800px;
    height: 100vh;
  }
  .login-page .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .login-page .signin-signup,
  .login-page .container.sign-up-mode .signin-signup {
    left: 50%;
  }

  .login-page .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .login-page .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .login-page .right-panel {
    grid-row: 3 / 4;
  }

  .login-page .left-panel {
    grid-row: 1 / 2;
  }

  .login-page .image {
    /* width: 200px; */
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .login-page .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .login-page .panel h3 {
    font-size: 1.2rem;
  }

  .login-page .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .login-page .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .login-page .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .login-page .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .login-page .container.sign-up-mode .left-panel .image,
  .login-page .container.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .login-page .container.sign-up-mode .right-panel .image,
  .login-page .container.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .login-page .right-panel .image,
  .login-page .right-panel .content {
    transform: translateY(300px);
  }

  .login-page .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  /* .login-page form {
    padding: 0 1.5rem;
  } */

  .login-page .image {
    /* display: none; */
  }
  .login-page .panel .content {
    padding: 0.5rem 1rem;
  }
  .login-page .container {
    padding: 1.5rem;
  }

  .login-page .container:before {
    bottom: 72%;
    left: 50%;
  }

  .login-page .container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}

@media only screen and (max-width: 1024px) {
  .login-page.page-containners-main {
    margin-top: 0px !important;
  }
  .login-page .image {
    margin-top: 0px;
    width: 100%;
  }
  .login-page .panel h3 {
    font-size: 18px;
    margin: 0 90px 10px 90px !important;
  }
  .login-page .btn.transparent {
    width: 100px;
    height: 35px;
    padding: 5px 10px;
  }
  .login-page .signin-signup {
    width: 60%;
  }
  .tab-containner.pt-2 a img {
    width: 30px;
  }
  .tab-containner a .tooltip-text {
    font-size: 10px;
  }
  .login-page .title {
    font-size: 26px;
  }
  .login-page .input-field {
    width: 90%;
  }

  .login-page .btn {
    width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  .login-page .signin-signup {
    width: 100%;
  }
  .login-page .login-page-main-logo-icon {
    /* transform: translate(-90%, -350%); */
    transform: translate(-130%, -350%);
  }
  .login-page .image {
    /* margin-top: 50px; */
    width: 45%;
  }
  .login-page .panel {
    margin-top: 0px !important;
  }
  .main-logo-crm {
    width: 200px;
  }
  .login-page .title {
    font-size: 26px;
  }
  .login-page form {
    margin: 0rem 5rem;
  }
}
@media only screen and (max-width: 668px) {
  .login-page .login-page-main-logo-icon {
    transform: translate(-70%, -300%);
  }
}

@media only screen and (max-width: 468px) {
  .login-page .login-page-main-logo-icon {
    transform: translate(-40%, -400%);
  }
  .main-logo-crm {
    width: 180px;
  }
  .login-page form.sign-in-form,
  .login-page .tab-containner {
    margin: 10px;
    width: 95%;
  }
  .login-page .image {
    width: 75%;
  }
  /* .login-page .input-field {
    width: 100%;
  } */
  .login-page .forget-password-login-page {
    width: 100%;
  }
  .tab-containner.pt-2 a img {
    width: 30px !important;
  }
}
@media only screen and (max-width: 350px) {
  .main-logo-crm {
    width: 170px;
    margin: -3px;
  }
}
.forget-password-title {
  width: 100%;
  text-align: center;
  padding: 20px;
  margin: 0 !important;
  border: 1px solid gray;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #1c4b44;
  color: #fff !important;
}
.input-field button.send-otp-btn {
  position: absolute;
  right: 0;
  outline: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px;
  transition: 0.5s;
}
.input-field button.send-otp-btn:hover {
  transition: 0.5s;
  color: #fff;
  background-color: #002c26;
}

.main-page-404-containner .page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-page-404-containner .page_404 img {
  width: 100%;
}
.main-page-404-containner .four_zero_four_bg {
  /* background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif); */
  background-image: url("/svg/image-403.gif");
  height: 400px;
  /* height: 75vh; */
  background-position: center;
  background-repeat: no-repeat;
}
.main-page-404-containner .image-parant-404 {
  width: 100%;
  height: 80vh;
  max-height: 100vh;
}
.main-page-404-containner .four_zero_four_bg h1 {
  font-size: 80px;
}

.main-page-404-containner .four_zero_four_bg h3 {
  font-size: 80px;
}

.main-page-404-containner .link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #00433a;
  margin: 20px 0;
  display: inline-block;
}
.main-page-404-containner .contant_box_404 {
  margin-top: -50px;
}
@font-face {
  font-family: "arvo";
  src: url("/font/arvo.ttf");
  font-display: swap;
}
.main-page-404-containner .contant_box_404 * {
  font-family: "arvo" !important;
}
@media only screen and (max-width:768px){
  .main-page-404-containner .page_404 div.w-100{
    display: flex;
    align-items: center;
    height: 90vh;
    justify-content: center;
  }
  .main-page-404-containner .page_404{
    padding: 0;
  }
  .main-page-404-containner .image-parant-404{
    height: auto !important; 
  }
}
.main-error-page-403 {
  /* background: #1b1b1b; */
  color: white;
  font-family: "Bungee", cursive;
  /* margin-top: 50px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.main-error-page-403 a {
  color: blue;
  text-decoration: none;
  font-size: 26px;
  font-family: "besthiesoulness" !important;
  transition: 0.5s;
}
.main-error-page-403 a:hover {
  transition: 0.5s;
  text-decoration: underline;
}
.main-error-page-403 svg {
  width: 50vw;
}
.main-error-page-403 .lightblue {
  fill: #444;
}
/* .main-error-page-403 .eye {
  cx: calc(115px + 30px * var(--mouse-x));
  cy: calc(50px + 30px * var(--mouse-y));
} */
.main-error-page-403 #error-page {
  overflow: hidden;
}
.main-error-page-403 .error-page {
  background-color: #fff;
}
.main-error-page-403 .error-text {
  font-size: 120px;
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  max-height: 100vh;
}
.main-error-page-403 .alarm {
  animation: alarmOn 0.5s infinite;
}
@keyframes alarmOn {
  to {
    fill: darkred;
  }
}

.agent-permition-table-main-containner {
  font-family: "memv";
}
.agent-permition-table-main-containner thead tr th {
  background-color: #025347;
  color: #fff;
  font-weight: 500;
  padding: 10px;
}
.agent-permition-table-main-containner thead tr .set-permition-column-with {
  width: 140px !important;
}
.agent-permition-table-main-containner thead tr th {
  font-size: 14px;
}
.agent-permition-table-main-containner input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  margin: 0px;
}
.agent-permition-table-main-containner input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  outline: 1px rgba(255, 255, 255, 0.2) solid;
  transform: translate(-50%, -50%);
  border: 2px solid #00493f;
  background-color: #fff;
  border-radius: 5px;
  transition: 0.5s;
}
.agent-permition-table-main-containner input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  left: 50%;
  background-color: #0a7968;
  outline: 1px rgba(255, 255, 255, 0.2) solid;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 4px 1px #fff;
  border-radius: 5px;
  color: #fff !important;
  transition: 0.5s;
  border: none;
}
.agent-permition-table-main-containner input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 6px;
  height: 10px;
  border: solid;
  border-width: 0 2px 2px 0;
  border-color: inherit;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  color: #fff;
}
.feature__containner {
  max-height: 300px;
  overflow-y: auto;
}
.feature_main_containner {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.feature_main_containner .feature_chekboxs {
  width: 15px;
  height: 15px;
}
@media only screen and (min-width: 1024px) {
  .set-default-height-min {
    min-height: 70vh;
  }
}

/* #loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
#loader video {
  width: 20%;
  height: auto;
}
body.loaded #loader {
  display: none;
} */

/* body {
  height: 100vh;

  margin: 0;

  padding: 0;
} */

#loader-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.4);
  z-index: 9999;
  display: none;
}

#loader-overlay img {
  pointer-events: none;
  width: 135px;
  height: 135px;
}

#loader-overlay svg {
  font-family: "Russo One", sans-serif;
  display: block;
  width: 500px;
}

#loader-overlay svg text {
  text-transform: uppercase;
  animation: strokeloader 3s infinite alternate;
  stroke-width: 2;
  stroke: #004d42;
  font-size: 220px;
}

@keyframes strokeloader {
  0% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgba(0, 77, 66);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 5;
  }

  70% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgba(0, 77, 66);
  }

  80% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgba(0, 77, 66);
    stroke-width: 8;
  }

  100% {
    fill: rgba(0, 77, 66);
    stroke: rgba(0, 77, 66);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

/* ----------------   PDF STYLE   ----------------------- */
#pdf-content {
  padding: 20px;
}
#pdf-content .pdf-block {
  margin-bottom: 20px;
}
#pdf-content .grid-layout {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
  height: auto;
}
#pdf-content .grid-layout img {
  /* width: 100%; */
  /* height: auto; */
}

.success-btn,
.model_inner .sdvds button {
  background-color: #004d42;
  width: 150px;
  border: 2px solid #004d42;
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.success-btn:hover {
  background-color: #000;
}

.cancel-btn {
  border: 2px solid #004d42;
  width: 150px;
  background: none;
  color: #004d42;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-btn:hover {
  background-color: #004d42;
  color: #fff;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
p {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.7px;
}

.btn {
  font-size: 14px;
}

*.btn:focus {
  box-shadow: none !important;
}

button:focus {
  outline: none !important;
}
.filter_button {
  border: 1px solid #cccccc;
  /* color: #004d42; */
  height: 40px;
  font-size: 14px !important;
  font-family: "memv" !important;
  border-radius: none !important;
}
.filter_button:hover {
  background-color: #004d42;
  color: #fff;
}
.message {
  padding: 0 25px;
  text-align: left;
  min-width: 100%;
  height: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease;
  font-family: "memv" !important;
  font-weight: normal;
}

.message.active {
  opacity: 1;
}
.listing_button {
  padding: 0 !important;
  border: 1px solid #cccccc !important
  ;
}
#searchtrans {
  padding: 0 0 0 5px !important;
}
#propunitno,
#searchtrans {
  padding: 0 0 0 5px !important;
}
.datatable-header-main {
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 20px 0;
}
.search_box {
  border: 1px solid #cccccc;
  padding: 5px 5px 5px 28px;
  font-size: 16px !important;
  outline: none;
  height: 40px;
  width: 250px;
}
.add_button {
  height: 40px;
  font-size: 16px !important;
  width: 200px;
}
.container {
  width: 100% !important;
  max-width: 1500px !important;
}

.container .ddvZdT {
  white-space: wrap !important;
}
.container .ejfNUe {
  min-height: 300px;
}
.css-13cymwt-control,
.css-t3ipsp-control {
  border-radius: 0px !important;
}
.css-1nmdiq5-menu {
  z-index: 9 !important;
}
.tiny_add_text_button_container {
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-around;
  background-color: whitesmoke;
  padding: 10px;
}
.tiny_add_text_button_container .tiny_add_text_button {
  padding: 5px 10px;
  color: #fff;
  background-color: #004d42;
  border: 2px solid #004d42;
  outline: none;
  font-size: 14px;
  transition: 0.3s;
}
.tiny_add_text_button_container .tiny_add_text_button:hover {
  transition: 0.3s;
  color: #000;
  background-color: #fff;
  border: 2px solid #004d42;
}
@media only screen and (max-width: 768px) {
  #mouse-follower,
  #dot {
    display: none !important;
  }
}
@media only screen and (max-width: 600px) {
  .tiny_add_text_button_container {
    display: block;
  }
  .tiny_add_text_button_container .tiny_add_text_button {
    width: 100%;
    margin: 10px 0;
    display: block;
  }
}

