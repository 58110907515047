/* customDatePickerStyle.css */

.react-date-picker {
  border: 1px solid #ccc;
  border-radius: 0px;
  padding: 0px;
  /* width: 13.5% !important; */
  height: 36px !important;
}

.react-date-picker__wrapper {
  display: flex;
  align-items: center;
  border: none !important;
  font-size: 14px;
}

.react-date-picker__wrapper .react-date-picker__inputGroup {
  display: flex !important;
  align-items: center !important;
  padding: 0 0 0 30px;
}

.react-date-picker__inputGroup__input {
  border: none;
  outline: none;
  padding: 5px;
  /* width: 20px !important; */
}

.react-date-picker__calendar {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.react-date-picker__button {
  background: none;
  border: none;
  cursor: pointer;
}

.react-date-picker__clear-button__icon,
.react-date-picker__calendar-button__icon {
  width: 16px;
  color: gray;
}

.react-date-picker__calendar-button {
  position: absolute;
  left: 0px;
}
.react-calendar__month-view__days__day--weekend {
  color: black;
}
.react-date-picker__calendar {
  z-index: 9 !important;
}
.react-calendar__tile--active {
  background: #004e41 !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}

/* DATE RANGE PICKER STYLE START...... */
.date_range_picker_containner {
  position: relative;
}
.date_range_picker_containner input {
  padding: 5px 5px 5px 35px !important;
  border-radius: 0;
  border: 1px solid #d9d9d9;
}
.date_range_picker_containner img {
  position: absolute;
  left: 10px;
  width: 20px;
  top: 10px;
}
/* DATE RANGE PICKER STYLE END...... */
