.call-lead-details-containner-main {
  padding: 20px;
}
.call-lead-details-containner {
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
  width: 100%;
  display: flex;
  color: #fff;
  min-width: 1000px;
}
.call-lead-details-containner-header {
  width: 25%;
  min-width: 250px;
}
.call-lead-details-containner-header p {
  text-align: center;
  padding: 10px;
  margin: 0px;
  font-size: 14px;
}
.call-lead-details-data-containner {
  width: 100%;
  padding: 20px;
  display: flex;
  min-width: 1000px;
}
.call-lead-details-data-containner-parant {
  border-right: 1px solid gray;
  width: 25%;
  min-width: 250px;
}
.call-lead-details-data-containner-data p {
  font-size: 12px;
}
.search-box-in-filter-main {
  position: relative;
}
.search-box-in-filter-main .search-box-in-filter-main-input {
  padding: 5px 10px 5px 30px;
  outline: none;
  font-size: 14px;
  border: 1px solid #cccccc;
  height: 35px;
}
.search-box-in-filter-main i {
  position: absolute;
  left: 20px;
  top: 10px;
}
.call-lead-details-containners-table {
  /* box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3); */
  overflow-x: auto;
  border: 1px solid #d9d9d9;
  border-top: 0px;
}
.recording-show-details {
  border: 1px solid black;
  border-radius: 20px;
  padding: 5px;
  margin: 5px 10px;
  width: 90%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.recording-show-details i {
  color: #002c26;
}
.details-audio {
  width: 100%;
  font-size: 10px !important;
  border-radius: 20px !important;
  padding: 5px !important;
}
.caller-id-row {
  display: flex;
}
.caller-id-row .radio-btn-caller {
  font-size: 12px;
  width: 15% !important;
  margin: 0px 10px 0 0;
}
.details-audio-data-table {
  border-radius: 20px !important;
  padding: 10px !important;
}
.filtered-call-lead-options {
  justify-content: space-between;
}
.filtered-call-lead-options .select-option-value {
  width: 200px;
}
.data-table-audio-show {
  width: 90%;
}
.show-call-detail-comment-parant {
  overflow-y: auto;
  scrollbar-width: thin;
}
.show-call-detail-comment {
  margin: 10px;
  border-radius: 20px 20px 20px 0px;
  background-color: #e7e7e7;
  padding: 20px;
}
.recording-show-details .loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #004e41;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin-loader 1.5s linear infinite;
  text-align: center;
  display: block;
}

@keyframes spin-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer;
}
.search-box-datatable-search i {
  position: absolute;
  top: 10px;
  left: 20px;
}

.lead-setting-toggle-btn {
  border: 1px solid black;
  border-radius: 3px;
  width: 100px;
  height: 30px;
  background: linear-gradient(to right, #004d42 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 1s ease-out;
}
.lead-setting-toggle-btn-animate {
  color: #fff;
  background: linear-gradient(to right, #004d42 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 1s ease-out;
}
.data-table-single-search-input-box-container {
  margin: 20px 0;
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
}
.data-table-single-search-input-box-container input {
  border: 1px solid #cccccc;
  padding: 5px 5px 5px 30px;
  width: 250px;
  outline: none;
}
.data-table-single-search-input-box-container i {
  position: absolute;
  top: 12px;
  right: 225px;
  color: #656565;
}
@keyframes bgMove {
  from {
    background-position: left bottom;
  }
  to {
    background-position: right bottom;
  }
}

.leads-setting {
  height: 57.4vh;
}
.trash-image {
  width: 25px;
  cursor: pointer;
}

/* Lead setting style end */
