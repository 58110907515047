.profile-box {
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
  color: #fff;
  position: relative !important;
  overflow: hidden;
}

.profile-box .cartoon {
  position: absolute;
  bottom: -20%;
  animation: bounce-effect 5s infinite ease-in;
  right: -5px !important;
  text-align: right;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}
.profile-box .cartoon img {
  max-width: 250px;
}

[dir="rtl"] .profile-box .cartoon {
  right: unset !important;
  left: 5px;
}

@keyframes bounce-effect {
  0% {
    transform: translateY(0);
    transition: 0.5s;
  }

  50% {
    transform: translateY(-5px);
    transition: 0.5s;
  }

  100% {
    transform: translateY(0);
    transition: 0.5s;
  }
}

@media (max-width: 1660px) {
  .profile-box .cartoon {
    right: 30px;
    text-align: right;
  }

  [dir="rtl"] .profile-box .cartoon {
    left: -6px;
    text-align: left;
  }

  .profile-box .cartoon img {
    /* width: 80%; */
  }
}

@media (max-width: 1500px) {
  .profile-box .cartoon img {
    /* width: 60%; */
  }
}

@media (max-width: 767px) {
  .profile-box .cartoon {
    right: 10px;
  }

  [dir="rtl"] .profile-box .cartoon {
    left: -10px;
  }

  .profile-box .cartoon img {
    /* width: 52%; */
  }
}

@media (max-width: 575px) {
  .profile-box .cartoon {
    right: 30px;
  }

  [dir="rtl"] .profile-box .cartoon {
    left: 10px;
  }

  .profile-box .cartoon img {
    /* width: 48%; */
  }
}
@media (max-width: 435px) {
  .profile-box .cartoon img {
    width: 200px;
  }
}

.profile-box .greeting-user p {
  width: 60%;
}

@media (max-width: 1500px) {
  .profile-box .greeting-user p {
    width: 70%;
  }
}

@media (max-width: 1199px) {
  .profile-box .greeting-user p {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .profile-box .greeting-user p {
    width: 98%;
  }
}

@media (max-width: 600px) {
  .profile-box .greeting-user p {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .profile-box .greeting-user p {
    width: 98%;
  }
  .profile-box .cartoon {
    bottom: -15%;
  }
}

.profile-box .whatsnew-btn {
  margin-top: 3.5rem;
}

@media (max-width: 1500px) {
  .profile-box .whatsnew-btn {
    margin-top: 1.7rem;
  }
}

@media (max-width: 991px) {
  .profile-box .whatsnew-btn {
    margin-top: 3.5rem;
  }
}

@media (max-width: 638px) {
  .profile-box .whatsnew-btn {
    margin-top: 2rem;
  }
}

@media (max-width: 767px) {
  .profile-box .whatsnew-btn .btn {
    padding: 6px 10px;
  }
}

.widget-1 {
  background-size: cover;
  margin-bottom: 25px;
}

.widget-1 i {
  font-weight: 700;
  font-size: 12px;
}

.widget-1 .f-w-500 {
  display: flex;
  align-items: center;
}

@media (max-width: 1580px) and (min-width: 1200px) {
  .widget-1 .f-w-500 {
    display: none;
  }
}

.widget-1 .card-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 18px 25px;
}

@media (max-width: 1720px) {
  .widget-1 .card-body {
    padding: 18px;
  }
}

.widget-1 .widget-round {
  position: relative;
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-radius: 100%;
}

.widget-1 .widget-round .bg-round {
  width: 56px;
  height: 56px;
  box-shadow: 1px 2px 21px -2px rgba(214, 214, 227, 0.83);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: 6px;
  position: relative;
  z-index: 1;
}

.widget-1 .widget-round .bg-round svg {
  width: 24px;
  height: 24px;
}

.widget-1 .widget-round .bg-round .half-circle {
  height: 52px;
  position: absolute;
  left: -9px;
  width: 35px;
  bottom: -8px;
  background: #fff;
  z-index: -1;
}

@media (max-width: 1600px) {
  .widget-1 .widget-round .bg-round {
    width: 40px;
    height: 40px;
  }

  .widget-1 .widget-round .bg-round svg {
    width: 22px;
    height: 22px;
  }

  .widget-1 .widget-round .bg-round .half-circle {
    height: 40px;
    left: -10px;
    width: 30px;
    bottom: -8px;
  }
}

.widget-1 .widget-round.primary {
  border-color: #004d42;
}

.widget-1 .widget-round.secondary {
  border-color: #004d42;
}

.widget-1 .widget-round.success {
  border-color: #54ba4a;
}

.widget-1 .widget-round.warning {
  border-color: #ffaa05;
}

.widget-1 .widget-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

@media (max-width: 1600px) {
  .widget-1 .widget-content {
    gap: 10px;
  }
}

.widget-1 .widget-content h4 {
  margin-bottom: 4px;
}

.widget-1:hover {
  transform: translateY(-5px);
  transition: 0.5s;
}

.widget-1:hover .widget-round .svg-fill:not(.half-circle) {
  animation: tada 1.5s ease infinite;
}

#hour,
#minute,
#second {
  transform-origin: 300px 300px;
  transition: transform 0.5s ease-in-out;
}

.hour-marks {
  fill: none;
  stroke: #52526c;
  stroke-width: 9;
  stroke-miterlimit: 10;
}

.hour-hand {
  fill: none;
  stroke: #ff7e00;
  stroke-width: 17;
  stroke-miterlimit: 10;
}

.sizing-box {
  fill: none;
}

.clockbox {
  margin-bottom: -5px;
}

.clockbox svg {
  width: 45px;
  height: 45px;
}

@media (max-width: 767px) {
  .clockbox svg {
    height: 36px;
  }
}

.clockbox,
#clock {
  width: 100%;
}

.circle {
  fill: #fff;
  stroke: rgba(255, 255, 255, 0.4);
  stroke-width: 80px;
  stroke-miterlimit: 10;
}

.mid-circle {
  fill: #000;
}

.minute-hand {
  fill: none;
  stroke: #ff7e00;
  stroke-width: 11;
  stroke-miterlimit: 10;
}

.second-hand {
  fill: none;
  stroke: #ffaa05;
  stroke-width: 15px;
  stroke-miterlimit: 10;
}

.card {
  margin-bottom: 30px;
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 15px;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}

.card:hover {
  box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
  transition: all 0.3s ease;
}

.custom_text_date {
  width: 90% !important;
}

.card-body.main_card_body {
  padding: 70px 30px !important;
  /* padding: 65px 30px !important; */
}
.profile_show_name_containner {
  line-height: 20px;
}
.profile_show_name_containner span {
  color: #fff;
  font-size: 14px;
  font-weight: normal !important;
}
.profile_show_name_containner span.admin-name {
  font-weight: normal !important;
  font-size: 12px;
}
.profile .img-profile-header {
  width: 45px;
  padding: 5px;
  background: #fff;
  border-radius: 50px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .dashbord-animation-container-main {
    width: 99% !important;
  }
  .ag-courses-item_link:hover,
  .ag-courses-item_link:hover .ag-courses-item_title span {
    color: #000 !important;
  }
}
/* --------------- */

.whatch-main-containner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin: 0;
}

.whatch-main-containner canvas {
  /* border: 0px solid #121212; */
  /* border-radius: 50%; */
  width: 200px;
  position: absolute;
  /* top: -15px;
  right: -50px; */
  top: 20px;
  right: -85px;
}
.whatch-main-containner p {
  position: absolute;
  right: 40px;
  top: 90px;
}
