/* #loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
#loader video {
  width: 20%;
  height: auto;
}
body.loaded #loader {
  display: none;
} */

/* body {
  height: 100vh;

  margin: 0;

  padding: 0;
} */

#loader-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.4);
  z-index: 9999;
  display: none;
}

#loader-overlay img {
  pointer-events: none;
  width: 135px;
  height: 135px;
}

#loader-overlay svg {
  font-family: "Russo One", sans-serif;
  display: block;
  width: 500px;
}

#loader-overlay svg text {
  text-transform: uppercase;
  animation: strokeloader 3s infinite alternate;
  stroke-width: 2;
  stroke: #004d42;
  font-size: 220px;
}

@keyframes strokeloader {
  0% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgba(0, 77, 66);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 5;
  }

  70% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgba(0, 77, 66);
  }

  80% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgba(0, 77, 66);
    stroke-width: 8;
  }

  100% {
    fill: rgba(0, 77, 66);
    stroke: rgba(0, 77, 66);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

/* ----------------   PDF STYLE   ----------------------- */
#pdf-content {
  padding: 20px;
}
#pdf-content .pdf-block {
  margin-bottom: 20px;
}
#pdf-content .grid-layout {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  height: auto;
}
#pdf-content .grid-layout img {
  /* width: 100%; */
  /* height: auto; */
}
