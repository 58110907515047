@import url("https://fonts.googleapis.com/css?family=Bungee");
.main-error-page-403 {
  /* background: #1b1b1b; */
  color: white;
  font-family: "Bungee", cursive;
  /* margin-top: 50px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.main-error-page-403 a {
  color: blue;
  text-decoration: none;
  font-size: 26px;
  font-family: "besthiesoulness" !important;
  transition: 0.5s;
}
.main-error-page-403 a:hover {
  transition: 0.5s;
  text-decoration: underline;
}
.main-error-page-403 svg {
  width: 50vw;
}
.main-error-page-403 .lightblue {
  fill: #444;
}
/* .main-error-page-403 .eye {
  cx: calc(115px + 30px * var(--mouse-x));
  cy: calc(50px + 30px * var(--mouse-y));
} */
.main-error-page-403 #error-page {
  overflow: hidden;
}
.main-error-page-403 .error-page {
  background-color: #fff;
}
.main-error-page-403 .error-text {
  font-size: 120px;
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  max-height: 100vh;
}
.main-error-page-403 .alarm {
  animation: alarmOn 0.5s infinite;
}
@keyframes alarmOn {
  to {
    fill: darkred;
  }
}
