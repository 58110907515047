.footer {
  background: linear-gradient(103.75deg, #002c26 -13.9%, #004d42 79.68%);
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  padding: 15px;
  transition: 0.5s;
  position: sticky;
  bottom: 0px;
}

footer p {
  color: #fff;
}

footer {
  margin: 0 !important;
  width: 100%;
  z-index: 9999;
}
