@font-face {
  font-family: "memv";
  src: url("/font/memv.woff2");
  font-display: swap;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "memv" !important;
  font-weight: 600;
}
p,
span,
a,
ul li,
label,
button {
  font-family: "memv" !important;
}

a {
  font-size: 0.875rem;
  text-decoration: none;
  color: var(--theme-deafult);
  transition: 0.5s all;
}

a:hover {
  color: var(--theme-deafult);
  transition: all 0.5s all;
}

a:focus-visible {
  outline: none;
}

a[disabled] {
  cursor: not-allowed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
}

* a {
  color: var(--theme-deafult);
  text-decoration: none;
  letter-spacing: 1px;
}
