.profile-model-header {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999999999;
  backdrop-filter: blur(5px);
  top: 0;
}
.profile-model-header .containner {
  width: 100%;
  height: 100vh;
  z-index: 9999;
}
.profile-model-header .hide {
  visibility: hidden;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.8) !important;
}

.profile-model-header .close {
  position: absolute;
  right: 0;
  top: 0;
  width: 51px;
  height: 51px;
  font-size: 0;
  cursor: pointer;
}

.profile-model-header .close:before,
.profile-model-header .close:after {
  content: "";
  position: absolute;
  background: #000;
  border-radius: 1px;
  left: 14px;
  right: 14px;
  top: 30px;
  height: 3px;
  transform: rotate(45deg);
}

.profile-model-header .close:after {
  transform: rotate(-45deg);
}

.profile-model-header .popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
  border-radius: 5px;
  width: 600px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.09),
    0px 11px 12px 7px rgba(0, 0, 0, 0.02);
  transition: all 120ms;
  z-index: 99;
  padding: 25px;
  color: #5e5e5e;
}

@media screen and (max-width: 1000px) {
  .profile-model-header .popup {
    width: 80%;
  }
}
@media only screen and (max-width: 430px) {
  .profile-model-header .name_email {
    display: block !important;
  }
  .profile-model-header .name_email input {
    width: 100% !important;
  }
  .profile-model-header .popup-header h1 {
    font-size: 20px !important;
  }
  .profile-model-header .submit_button input {
    font-size: 16px !important;
  }
  .profile-model-header .close {
    width: 45px !important;
    height: 45px !important;
  }
  .profile-model-header .avatar-upload .avatar-preview {
    width: 150px !important;
    height: 150px !important;
  }
  .profile-model-header .avatar-upload {
    display: flex;
    justify-content: center;
  }
}

.profile-model-header .popup-header {
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
}
.profile-model-header .popup-header h1 {
  font-size: 24px;
}

.profile-model-header .popup-body {
  padding: 25px 0;
}

.profile-model-header .button {
  padding: 10px 15px;
  border: 1px solid #f5f5f5;
  text-transform: uppercase;
  box-shadow: 1px 2px 3px #f5f5f5;
  color: #5e5e5e;
  cursor: pointer;
  transition: all 120ms ease-in;
  outline: none;
}

.profile-model-header .button:hover {
  box-shadow: 1px 3px 5px #e9e9e9;
  transform: translateY(-1px);
}

.profile-model-header .avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 20px auto;
}
.profile-model-header .avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}
.profile-model-header .avatar-upload .avatar-edit input {
  display: none;
}
.profile-model-header .avatar-upload .avatar-edit input + label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
}
.profile-model-header .avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.profile-model-header .avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.profile-model-header .name_email {
  display: flex;
  justify-content: space-between;
}

.profile-model-header .name_email input {
  width: 50%;
  border-bottom: 1px solid #000 !important;
  margin: 0 10px;
  border: none;
  padding: 15px 15px 15px 0;
}

.profile-model-header .phone_number input {
  width: 97%;
  border-bottom: 1px solid #000 !important;
  margin: 10px;
  border: none;
  padding: 15px 15px 15px 0;
}

.profile-model-header .submit_button input {
  background: #004d42;
  padding: 10px;
  color: #fff;
  font-size: 20px;
  border: 2px solid #004d42;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 3px;
}

.profile-model-header .submit_button input:hover {
  background: #fff;
  border-color: #004d42;
  color: #004d42 !important;
}
.input-field-popup,
.input-field-popup-email {
  position: relative;
  /* width: 100% !important; */
  gap: 20px;
}
.input-field-popup input,
.input-field-popup-email input {
  width: 100% !important;
  padding-left: 20px !important;
  outline: none;
}
.input-field-popup .user_name_imgs,
.input-field-popup-email .user_email_imgs {
  position: absolute;
  top: 20px;
  left: 5px;
}
.phone_number {
  position: relative;
  gap: 20px !important;
}
.phone_number .phone_number_input {
  width: 100% !important;
  padding-left: 20px !important;
  outline: none;
}
.phone_number .phone_number_img {
  position: absolute;
  top: 30px;
  left: 5px;
}
.phone_number .password-icon-hide-show {
  position: absolute;
  top: 30px;
  right: 0px;
}
.name_email .password-icon-hide-show {
  position: absolute;
  top: 20px;
  right: 0px;
}
